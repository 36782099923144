import { persistReducer } from 'redux-persist';
import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../redux/reducers';

const MIGRATION_DEBUG = false;

const migration = {
  2: (previousVersionState: any) => {
    const newState = {
      ...previousVersionState,
      version: '2.0',
      number: {
        ...previousVersionState.number,
        lastUpdate: new Date(),
      },
    };

    return newState;
  },
};

const persistConfig = {
  key: 'root',
  storage,
  version: 1.5, // bump up version every time any change is made in the redux state
  whitelist: [
    'batches',
    'channels',
    'batchParticipants',
    'progressReports',
    'tasks',
    'projects',
    'notifications',
    'users',
    'courses',
    'chat',
    'timeEntries',
    'goals'
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export { persistedReducer };
