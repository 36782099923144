import React from 'react';
import styles from './TaskDetails.module.scss';
import TaskStatus from '../TaskStatus';
import RichInput from '../RichInput';
import { TaskType } from '../../types';
import InLineRichInput from '../InLineRichInput';
import TaskAssignTo from '../TaskAssignTo';
import { useAppDispatch } from '../../redux/hooks';
import Actions from '../Actions';
import { deleteTaskRequest } from '../../redux/Tasks/TasksSlice';
import { DeleteOutlined } from '@ant-design/icons';
import TaskChecklists from '../TaskChecklists/TaskChecklists';
import CreateChecklist from '../TaskChecklists/components/CreateChecklist';

const descriptionStyle = {
  border: '1px solid #e2e2e2',
  borderRadius: 6,
  minHeight: 240,
  fontSize: 14,
  color: '#404040',
};

const titleStyles = {
  fontSize: 32,
  fontWeight: 600,
  lineHeight: 1.2,
  color: '#363636',
};

interface Props {
  visible: boolean;
  currentTask: TaskType;
  batchMemberId: string;
  description: string;
  title: string;
  onTitleInput: (e: any) => void;
  onClose: () => void;
  onBlur: () => void;
  onDescriptionInput: (e: any) => void;
}

const TaskDetails: React.FC<Props> = ({
  batchMemberId,
  visible,
  currentTask,
  title,
  description,
  onTitleInput,
  onClose,
  onDescriptionInput,
  onBlur,
}) => {
  const dispatch = useAppDispatch();

  const handleDeleteTask = () => {
    if (currentTask)
      dispatch(
        deleteTaskRequest({
          id: currentTask.id,
          projectId: currentTask.project_id,
        })
      );
    onClose();
  };

  return (
    <div className={styles['task-details-container']}>
      <div className={styles['task-status']}>
        <div
          className={
            batchMemberId
              ? styles['task-status-heading']
              : styles['admin-status-heading']
          }
        >
          <TaskStatus task={currentTask} visible={visible} />
          <TaskAssignTo task={currentTask} />
        </div>
        <Actions
          options={[
            {
              label: 'Delete',
              icon: <DeleteOutlined style={{ color: '#bc4841' }} />,
              handleAction: handleDeleteTask,
            },
          ]}
        />
      </div>
      <div className={styles['field']}>
        <InLineRichInput
          isOpen={true}
          initialValue={currentTask ? currentTask.title : ''}
          style={titleStyles}
          onBlur={onBlur}
          value={title}
          placeholder='Write title of the task'
          onInput={onTitleInput}
        />
      </div>
      <div className={styles['field']}>
        <RichInput
          isOpen={true}
          initialValue={currentTask ? currentTask.description : ''}
          value={description}
          style={descriptionStyle}
          placeholder='Write description ...'
          onInput={onDescriptionInput}
          onBlur={onBlur}
          placeholderStyle={{ padding: 16 }}
        />
      </div>
      {currentTask && (
        <div>
          <TaskChecklists
            projectId={currentTask.project_id}
            taskId={currentTask.id}
            checklists={currentTask.checklists}
          />
          <CreateChecklist
            projectId={currentTask.project_id}
            taskId={currentTask.id}
          />
        </div>
      )}
    </div>
  );
};

export default TaskDetails;
