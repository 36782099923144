import { axiosInstance } from '../api';

export const fetchDailyTimeSummary = async (memberId: string) => {
  const url = `/api/v1/batch_members/${memberId}/daily_time_summaries.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
