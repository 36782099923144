import { combineReducers } from '@reduxjs/toolkit';
import batchesReducer, { resetBatchesState } from './Batches/BatchesSlice';
import batchMembersReducer, {
  resetBatchMemberState,
} from './BatchMembers/BatchMembersSlice';
import batchMemberActivitiesReducer, {
  resetBatchMemberActivitiesState,
} from './BatchMemberActivities/BatchMemberActivitiesSlice';
import channelsSlice, { resetChannelsState } from './Channels/ChannelsSlice';
import taskReducer, { resetTasksState } from './Tasks/TasksSlice';
import progressReportsReducer, {
  resetProgressReportsState,
} from './ProgressReports/ProgressReportsSlice';
import projectsReducer, { resetProjectsState } from './Projects/ProjectsSlice';
import notificationsReducer, {
  resetNotificationsState,
} from './Notifications/NotificationsSlice';
import usersReducer, { resetUsersState } from './Users/UsersSlice';
import coursesReducer, {
  resetCourseState,
} from '../redux/Courses/CoursesSlice';
import chatReducer, { resetChatState } from './Chat/ChatSlice';
import timeEntriesReducer, {
  resetTimeEntriesState,
} from './TimeEntries/TimeEntriesSlice';
import dailyTimeSummaryReducer, {
  resetDailyTimeSummaryState,
} from './DailyTimeSummary/DailyTimeSummarySlice';
import GoalsReducer, { resetGoalsState } from './Goals/GoalsSlice';
import SpacesReducer, { resetSpacesState } from './Spaces/SpacesSlice';
import SprintsReducer, { resetSprintsState } from './Sprints/SprintsSlice';


const rootReducer = combineReducers({
  batches: batchesReducer,
  channels: channelsSlice,
  batchMembers: batchMembersReducer,
  batchMemberActivities: batchMemberActivitiesReducer,
  progressReports: progressReportsReducer,
  tasks: taskReducer,
  projects: projectsReducer,
  notifications: notificationsReducer,
  users: usersReducer,
  courses: coursesReducer,
  chat: chatReducer,
  timeEntries: timeEntriesReducer,
  dailyTimeSummary: dailyTimeSummaryReducer,
  goals: GoalsReducer,
  spaces: SpacesReducer,
  sprints: SprintsReducer,
});

export const resetAllStates = () => (dispatch: any) => {
  dispatch(resetBatchesState());
  dispatch(resetBatchMemberState());
  dispatch(resetBatchMemberActivitiesState());
  dispatch(resetChannelsState());
  dispatch(resetTasksState());
  dispatch(resetProgressReportsState());
  dispatch(resetProjectsState());
  dispatch(resetNotificationsState());
  dispatch(resetUsersState());
  dispatch(resetCourseState());
  dispatch(resetChatState());
  dispatch(resetTimeEntriesState());
  dispatch(resetDailyTimeSummaryState());
  dispatch(resetGoalsState());
  dispatch(resetSpacesState());
  dispatch(resetSprintsState());
};

export default rootReducer;
