import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  createChannel,
  fetchChannels,
  fetchChannel,
  createChannelMember,
  removeChannelMember,
} from './ChannelsAPI';
import { ChannelType } from '../../types';

interface SpaceType {
  currentChannel: ChannelType;
  channels: ChannelType[];
  directMessages: ChannelType[];
}

export interface ChannelsState {
  spaces: Record<string, SpaceType>;
  channelsLoading: boolean;
  channelLoading: boolean;
  currentSpaceId: string | null;
  isCreatingChannel: boolean;
  showChatNav: boolean;
  isMemberRemove: boolean;
}

const initialState: ChannelsState = {
  spaces: {},
  currentSpaceId: null,
  channelsLoading: false,
  channelLoading: false,
  isCreatingChannel: false,
  showChatNav: false,
  isMemberRemove: false,
};

export const fetchChannelsRequest = createAsyncThunk(
  'channel/fetchChannels',
  async (params: { spaceId: string; userId: string }) => {
    const { spaceId, userId } = params;
    return await fetchChannels(spaceId, userId);
  }
);

export const fetchChannelRequest = createAsyncThunk(
  'channel/fetchChannel',
  async (params: { channelId: string; spaceId: string }) => {
    const { channelId } = params;
    return await fetchChannel(channelId);
  }
);

export const createChannelRequest = createAsyncThunk(
  'channel/createChannel',
  async (params: {
    name?: string;
    spaceId: string;
    isDirect: boolean;
    isPrivate: boolean;
    members: string[];
  }) => {
    const { name, spaceId, isDirect, isPrivate, members } = params;
    return await createChannel(
      name ? name : '',
      spaceId,
      isDirect,
      isPrivate,
      members
    );
  }
);

export const createChannelMemberRequest = createAsyncThunk(
  'channels/createChannelMemberRequest',
  async (params: { channelId: string; userId: string }) => {
    const { channelId, userId } = params;
    return await createChannelMember(channelId, userId);
  }
);

export const removeChannelMemberRequest = createAsyncThunk(
  'channels/removeChannelMemberRequest',
  async (params: { memberId: string; channelId: string }) => {
    const { memberId } = params;
    return await removeChannelMember(memberId);
  }
);

export const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    updateCurrentChannel: (
      state,
      action: PayloadAction<{ channel: ChannelType; spaceId: string }>
    ) => {
      state.spaces[action.payload.spaceId]['currentChannel'] =
        action.payload.channel;
    },
    updateShowChatNav: (state, action: PayloadAction<boolean>) => {
      state.showChatNav = action.payload;
    },
    updateSpaceId: (state, action: PayloadAction<string>) => {
      state.currentSpaceId = action.payload;
    },
    resetChannelsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChannelsRequest.pending, (state) => {
        state.channelsLoading = true;
      })
      .addCase(fetchChannelsRequest.fulfilled, (state, action) => {
        state.channelsLoading = false;
        const spaceId = action.meta.arg.spaceId;
        if (spaceId) {
          state.spaces[spaceId] = state.spaces[spaceId] || {};
          state.spaces[spaceId]['channels'] = action.payload.channels;
          state.spaces[spaceId]['directMessages'] =
            action.payload.direct_messages;
        }
      })
      .addCase(fetchChannelsRequest.rejected, (state) => {
        state.channelsLoading = false;
      })
      .addCase(fetchChannelRequest.pending, (state) => {
        state.channelLoading = true;
      })
      .addCase(fetchChannelRequest.fulfilled, (state, action) => {
        state.channelLoading = false;
        const spaceId = action.meta.arg.spaceId;
        if (spaceId) {
          state.spaces[spaceId] = state.spaces[spaceId] || {};
          state.spaces[spaceId]['currentChannel'] = action.payload;
        }
      })
      .addCase(fetchChannelRequest.rejected, (state) => {
        state.channelLoading = false;
      })
      .addCase(createChannelRequest.pending, (state) => {
        state.isCreatingChannel = true;
      })
      .addCase(createChannelRequest.fulfilled, (state, action) => {
        state.isCreatingChannel = false;
        let channel = action.payload;
        const spaceId = action.meta.arg.spaceId;
        if (spaceId && channel.is_direct) {
          state.spaces[spaceId]['directMessages'] = [
            ...state.spaces[spaceId]['directMessages'],
            channel,
          ];
          state.spaces[spaceId]['currentChannel'] = channel;
        } else {
          state.spaces[spaceId]['channels'] = [
            ...state.spaces[spaceId]['channels'],
            channel,
          ];
          state.spaces[spaceId]['currentChannel'] = channel;
        }
      })
      .addCase(createChannelRequest.rejected, (state) => {
        state.isCreatingChannel = false;
      })
      .addCase(createChannelMemberRequest.pending, (state) => {
        state.isCreatingChannel = false;
      })
      .addCase(createChannelMemberRequest.fulfilled, (state, action) => {
        state.isCreatingChannel = false;
        const { channelId } = action.meta.arg;
        const spaceId = state.currentSpaceId;

        if (spaceId && channelId) {
          const space = state.spaces[spaceId];
          const channelIndex = space.channels.findIndex(
            (spaceChannel) => spaceChannel.id === channelId
          );

          if (channelIndex !== -1) {
            const updatedChannel = { ...space.channels[channelIndex] };

            const isMemberAlreadyAdded =
              updatedChannel.channel_memberships.some(
                (membership) => membership.id === action.payload.id
              );

            if (!isMemberAlreadyAdded) {
              updatedChannel.channel_memberships.push(action.payload);
              space.channels[channelIndex] = updatedChannel;
              state.spaces[spaceId]['currentChannel'] = updatedChannel;
            }
          }
        }
      })
      .addCase(createChannelMemberRequest.rejected, (state) => {
        state.isCreatingChannel = false;
      })
      .addCase(removeChannelMemberRequest.pending, (state) => {
        state.isCreatingChannel = false;
        state.isMemberRemove = false;
      })
      .addCase(removeChannelMemberRequest.fulfilled, (state, action) => {
        state.isCreatingChannel = false;
        const { channelId, memberId } = action.meta.arg;
        const spaceId = state.currentSpaceId;

        if (spaceId && channelId) {
          const space = state.spaces[spaceId];
          const channelIndex = space.channels.findIndex(
            (spaceChannel) => spaceChannel.id === channelId
          );

          if (channelIndex !== -1) {
            const updatedChannel = { ...space.channels[channelIndex] };

            const memberIndex = updatedChannel.channel_memberships.findIndex(
              (membership) => membership.id === memberId
            );

            if (memberIndex) {
              updatedChannel.channel_memberships.splice(memberIndex, 1);
              space.channels[channelIndex] = updatedChannel;
              state.spaces[spaceId]['currentChannel'] = updatedChannel;
              state.isMemberRemove = true;
            }
          }
        }
      })
      .addCase(removeChannelMemberRequest.rejected, (state) => {
        state.isCreatingChannel = false;
        state.isMemberRemove = false;
      });
  },
});

export const {
  updateCurrentChannel,
  updateShowChatNav,
  updateSpaceId,
  resetChannelsState,
} = channelsSlice.actions;

export const selectSpaces = (state: RootState) => state.channels.spaces;
export const selectChannelsLoading = (state: RootState) =>
  state.channels.channelsLoading;
export const selectChannelLoading = (state: RootState) =>
  state.channels.channelLoading;
export const selectShowChatNav = (state: RootState) =>
  state.channels.showChatNav;
export const selectCurrentSpaceId = (state: RootState) =>
  state.channels.currentSpaceId;
export const selectIsMemberRemove = (state: RootState) =>
  state.channels.isMemberRemove;
export const selectCurrentChannel = (state: RootState) => {
  const spaceId = state.channels.currentSpaceId;
  const currentChannel = spaceId
    ? state.channels.spaces[spaceId]?.currentChannel ?? null
    : null;
  return currentChannel;
};

export default channelsSlice.reducer;
