import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  BatchType,
  BatchMemberType,
  ProjectMemberType,
  ProjectType,
} from '../../types';
import {
  fetchProjects,
  fetchProjectDetails,
  fetchAllProjectMembers,
  fetchProjectMemberDetails,
  createProject,
  createProjectMember,
  updateProject,
  deleteProjectMember,
  deleteProject,
} from './ProjectsAPI';
import isEmpty from 'lodash.isempty';

export interface Projects {
  allProjects: ProjectType[];
  projectsLoading: boolean;
  projectDetails: ProjectType | null;
  showSideNav: boolean;
  allProjectMembers: ProjectMemberType[];
  memberDetails: ProjectMemberType | null;
  currentProject: ProjectType | null;
  projectDetailsLoading: boolean;
  currentParticipant: BatchMemberType | null;
  currentBatch: BatchType | null;
}

const initialState: Projects = {
  allProjects: [],
  projectsLoading: true,
  projectDetailsLoading: true,
  projectDetails: null,
  showSideNav: false,
  allProjectMembers: [],
  memberDetails: null,
  currentProject: null,
  currentParticipant: null,
  currentBatch: null,
};

export const fetchProjectsRequest = createAsyncThunk(
  'projects/fetchProjectsRequest',
  async (params: { batchId: string }) => {
    const response = fetchProjects(params.batchId);
    return response;
  }
);

export const fetchProjectMemberRequest = createAsyncThunk(
  'projects/fetchProjectMemberRequest',
  async (projectId: string) => {
    const response = fetchAllProjectMembers(projectId);
    return response;
  }
);

export const fetchProjectDetailsRequest = createAsyncThunk(
  'projects/fetchProjectDetailsRequest',
  async (projectId: string) => {
    const response = fetchProjectDetails(projectId);
    return response;
  }
);

export const fetchProjectMemberDetailsRequest = createAsyncThunk(
  'projects/fetchProjectMemberDetailsRequest',
  async (memberId: string) => {
    const response = fetchProjectMemberDetails(memberId);
    return response;
  }
);

export const createProjectRequest = createAsyncThunk(
  'projects/createProjectsRequest',
  async (params: { batchId: string; projectName: string }) => {
    const { projectName, batchId } = params;
    const response = createProject(batchId, projectName);
    return response;
  }
);

export const createProjectMemberRequest = createAsyncThunk(
  'projects/createProjectMemberRequest',
  async (params: { projectId: string; participantId: string }) => {
    const { projectId, participantId } = params;
    const response = createProjectMember(projectId, participantId);
    return response;
  }
);

export const updateProjectRequest = createAsyncThunk(
  'projects/updateProjectsRequest',
  async (params: { projectName: string; projectId: string }) => {
    const { projectName, projectId } = params;
    const response = updateProject(projectName, projectId);
    return response;
  }
);

export const deleteProjectRequest = createAsyncThunk(
  'projects/deleteProjectsRequest',
  async (projectId: string) => {
    const response = deleteProject(projectId);
    return response;
  }
);

export const deleteProjectMemberRequest = createAsyncThunk(
  'projects/deleteProjectMemberRequest',
  async (params: { memberId: string; projectId: string }) => {
    const { memberId, projectId } = params;
    const response = deleteProjectMember(memberId, projectId);
    return response;
  }
);

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetProjectsState: () => initialState,
    updateShowSideNav: (state, action: PayloadAction<boolean>) => {
      state.showSideNav = action.payload;
    },
    updateBatch: (state, action: PayloadAction<BatchType | null>) => {
      state.currentBatch = action.payload;
    },
    updateCurrentParticipant: (
      state,
      action: PayloadAction<BatchMemberType | null>
    ) => {
      state.currentParticipant = action.payload;
    },
    updateCurrentProject: (
      state,
      action: PayloadAction<ProjectType | null>
    ) => {
      state.currentProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsRequest.pending, (state) => {
        state.projectsLoading = true;
      })
      .addCase(fetchProjectsRequest.fulfilled, (state, action) => {
        state.projectsLoading = false;
        state.allProjects = action.payload;
        if (isEmpty(state.currentProject))
          state.currentProject = action.payload[0];
      })
      .addCase(fetchProjectsRequest.rejected, (state) => {
        state.projectsLoading = false;
      })
      .addCase(fetchProjectMemberRequest.pending, (state) => {
        state.projectsLoading = false;
      })
      .addCase(fetchProjectMemberRequest.fulfilled, (state, action) => {
        state.projectsLoading = false;
        state.allProjectMembers = action.payload.project_members;
      })
      .addCase(fetchProjectMemberRequest.rejected, (state) => {
        state.projectsLoading = false;
      })
      .addCase(fetchProjectDetailsRequest.pending, (state) => {
        state.projectDetailsLoading = true;
      })
      .addCase(fetchProjectDetailsRequest.fulfilled, (state, action) => {
        state.projectDetailsLoading = false;
        state.projectDetails = action.payload;
      })
      .addCase(fetchProjectDetailsRequest.rejected, (state) => {
        state.projectDetailsLoading = false;
      })
      .addCase(fetchProjectMemberDetailsRequest.pending, (state) => {
        state.projectsLoading = false;
      })
      .addCase(fetchProjectMemberDetailsRequest.fulfilled, (state, action) => {
        state.projectsLoading = false;
        state.memberDetails = action.payload;
      })
      .addCase(fetchProjectMemberDetailsRequest.rejected, (state) => {
        state.projectsLoading = false;
      })
      .addCase(createProjectRequest.pending, (state) => {
        state.projectsLoading = false;
      })
      .addCase(createProjectRequest.fulfilled, (state, action) => {
        state.projectsLoading = false;
        state.allProjects = [action.payload, ...state.allProjects];
      })
      .addCase(createProjectRequest.rejected, (state) => {
        state.projectsLoading = false;
      })
      .addCase(createProjectMemberRequest.pending, (state) => {
        state.projectsLoading = false;
      })
      .addCase(createProjectMemberRequest.fulfilled, (state, action) => {
        state.projectsLoading = false;
        if (state.projectDetails) {
          if (state.projectDetails.id === action.payload.project_id) {
            state.projectDetails.project_members.push(action.payload);
          }
        }
        const updatedProjects = state.allProjects.map((project) => {
          if (project.id.toString() === action.meta.arg.projectId.toString()) {
            const updatedProject = { ...project };
            updatedProject.project_members.push(action.payload);
            return updatedProject;
          }
          return project;
        });
        state.allProjects = updatedProjects;
      })
      .addCase(createProjectMemberRequest.rejected, (state) => {
        state.projectsLoading = false;
      })
      .addCase(updateProjectRequest.pending, (state) => {
        state.projectsLoading = false;
      })
      .addCase(updateProjectRequest.fulfilled, (state, action) => {
        const updatedProjects = [...state.allProjects];
        const index = state.allProjects.findIndex(
          (project) => project.id === action.payload.id
        );
        updatedProjects.splice(index, 1, action.payload);
        state.allProjects = [...updatedProjects];
      })
      .addCase(updateProjectRequest.rejected, (state) => {
        state.projectsLoading = false;
      })
      .addCase(deleteProjectRequest.pending, (state) => {
        state.projectsLoading = false;
      })
      .addCase(deleteProjectRequest.fulfilled, (state, action) => {
        const updatedProjects = state.allProjects.filter(
          (project) => project.id !== action.meta.arg
        );
        state.allProjects = updatedProjects;
      })
      .addCase(deleteProjectRequest.rejected, (state) => {
        state.projectsLoading = false;
      })
      .addCase(deleteProjectMemberRequest.pending, (state) => {
        state.projectsLoading = false;
      })
      .addCase(deleteProjectMemberRequest.fulfilled, (state, action) => {
        const updatedProjects = state.allProjects.map((project) => {
          if (project.id === action.meta.arg.projectId) {
            const updatedProject = { ...project };
            const index = project.project_members.findIndex(
              (member) => member.id === action.meta.arg.memberId
            );
            updatedProject.project_members.splice(index, 1);
            state.projectDetails = updatedProject;
            return updatedProject;
          }
          return project;
        });
        state.allProjects = updatedProjects;
      })
      .addCase(deleteProjectMemberRequest.rejected, (state) => {
        state.projectsLoading = false;
      });
  },
});

export const {
  updateShowSideNav,
  updateCurrentProject,
  updateBatch,
  updateCurrentParticipant,
  resetProjectsState,
} = projectSlice.actions;

export const selectAllProjects = (state: RootState) =>
  state.projects.allProjects;
export const selectProjectsLoading = (state: RootState) =>
  state.projects.projectsLoading;
export const selectProjectDetails = (state: RootState) =>
  state.projects.projectDetails;
export const selectProjectDetailsLoading = (state: RootState) =>
  state.projects.projectDetailsLoading;
export const selectShowSideNav = (state: RootState) =>
  state.projects.showSideNav;
export const selectProjectMembers = (state: RootState) =>
  state.projects.allProjectMembers;
export const selectProjectMemberDetails = (state: RootState) =>
  state.projects.memberDetails;
export const selectCurrentProject = (state: RootState) =>
  state.projects.currentProject;
export const selectCurrentBatch = (state: RootState) =>
  state.projects.currentBatch;
export const selectProjectParticipant = (state: RootState) =>
  state.projects.currentParticipant;

export default projectSlice.reducer;
