import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BatchMemberActivityType } from '../../types';
import { fetchAllActivities } from './BatchMemberActivitiesAPI';

export interface ActivitiesStateType {
  allActivities: BatchMemberActivityType[];
  activitiesLoading: boolean;
  totalPages: number;
}

const initialState: ActivitiesStateType = {
  allActivities: [],
  activitiesLoading: true,
  totalPages: 0,
};

export const fetchBatchMemberActivitiesRequest = createAsyncThunk(
  'batchMemberActivities/fetchBatchMemberActivitiesRequest',
  async (params: { batchId: string; page: number; perPage: number }) => {
    const { batchId, page, perPage } = params;
    const response = await fetchAllActivities(batchId, page, perPage);
    return response;
  }
);

export const BatchMemberActivitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    resetBatchMemberActivitiesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBatchMemberActivitiesRequest.pending, (state) => {
        state.activitiesLoading = true;
      })
      .addCase(fetchBatchMemberActivitiesRequest.fulfilled, (state, action) => {
        state.activitiesLoading = false;
        const page = action.meta.arg.page;
        if (page === 1) {
          state.allActivities = action.payload.activities;
        } else {
          state.allActivities = [
            ...state.allActivities,
            ...action.payload.activities,
          ];
        }
        state.totalPages = action.payload.meta.total_pages
      })
      .addCase(fetchBatchMemberActivitiesRequest.rejected, (state) => {
        state.activitiesLoading = false;
      });
  },
});

export const { resetBatchMemberActivitiesState } =
  BatchMemberActivitiesSlice.actions;

export const selectAllActivities = (state: RootState) =>
  state.batchMemberActivities.allActivities;
export const selectActivitiesLoading = (state: RootState) =>
  state.batchMemberActivities.activitiesLoading;
export const selectTotalPages = (state: RootState) =>
  state.batchMemberActivities.totalPages;

export default BatchMemberActivitiesSlice.reducer;
