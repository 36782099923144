import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GoalType, MilestoneType } from '../../types';
import {
  fetchAllGoals,
  fetchGoal,
  fetchMilestone,
  createGoal,
  createMilestone,
  updateGoal,
  updateMilestone,
  deleteGoal,
  deleteMilestone,
  createGoalAssignment,
  deleteGoalAssignment,
} from './GoalsAPI';
import { RootState } from '../store';

export interface GoalsStateType {
  allGoals: GoalType[];
  currentMilestone: MilestoneType | null;
  currentGoal: GoalType | null;
  goalsLoading: boolean;
  goalLoading: boolean;
  filteredGoals: GoalType[];
  milestonesLoading: boolean;
  milestoneLoading: boolean;
}

const initialState: GoalsStateType = {
  allGoals: [],
  currentMilestone: null,
  filteredGoals: [],
  currentGoal: null,
  goalsLoading: false,
  goalLoading: false,
  milestonesLoading: false,
  milestoneLoading: false,
};

export const fetchAllGoalsRequest = createAsyncThunk(
  'goals/fetchAllGoalsRequest',
  async (batchId: string) => {
    const response = await fetchAllGoals(batchId);
    return response;
  }
);

export const fetchGoalRequest = createAsyncThunk(
  'goals/fetchGoalRequest',
  async (id: string) => {
    const response = await fetchGoal(id);
    return response;
  }
);

export const fetchMilestoneRequest = createAsyncThunk(
  'goals/fetchMilestoneRequest',
  async (id: string) => {
    const response = await fetchMilestone(id);
    return response;
  }
);

export const createGoalRequest = createAsyncThunk(
  'goals/createGoalRequest',
  async (params: { batchId: string; title: string; description: string }) => {
    const { batchId, title, description } = params;
    const response = await createGoal(batchId, title, description);
    return response;
  }
);

export const createMilestoneRequest = createAsyncThunk(
  'goals/createMilestoneRequest',
  async (params: {
    goalId: string;
    trackableId: string;
    trackableType: string;
    title?: string;
    description?: string;
  }) => {
    const { goalId, title, description, trackableId, trackableType } = params;
    const response = await createMilestone(
      goalId,
      trackableId,
      trackableType,
      title,
      description
    );
    return response;
  }
);

export const updateGoalRequest = createAsyncThunk(
  'goals/updateGoalRequest',
  async (params: { attributes: any; goalId: string; batchId: string }) => {
    const { attributes, goalId, batchId } = params;
    const response = await updateGoal(attributes, goalId, batchId);
    return response;
  }
);

export const updateMilestoneRequest = createAsyncThunk(
  'goals/updateMilestoneRequest',
  async (params: { attributes: any; id: string }) => {
    const { attributes, id } = params;
    const response = await updateMilestone(attributes, id);
    return response;
  }
);

export const deleteGoalRequest = createAsyncThunk(
  'goals/deleteGoalRequest',
  async (id: string) => {
    const response = await deleteGoal(id);
    return response;
  }
);

export const deleteMilestoneRequest = createAsyncThunk(
  'goals/deleteMilestoneRequest',
  async (params: { id: string }) => {
    const response = await deleteMilestone(params.id);
    return response;
  }
);

export const createGoalAssignmentRequest = createAsyncThunk(
  'goals/createGoalAssignmentRequest',
  async (params: {
    goalId: string;
    participantId: string;
    dueDate: string;
  }) => {
    const { goalId, participantId, dueDate } = params;
    const response = createGoalAssignment(goalId, participantId, dueDate);
    return response;
  }
);

export const deleteGoalAssignmentRequest = createAsyncThunk(
  'goals/deleteGoalAssignmentRequest',
  async (params: { assigneeId: string; goalId: string }) => {
    const { assigneeId, goalId } = params;
    const response = deleteGoalAssignment(assigneeId, goalId);
    return response;
  }
);

export const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {
    filterGoals: (state, action: PayloadAction<string>) => {
      const goals = state.allGoals.filter((goal) => {
        const assignment = goal.goal_assignments.find(
          (assignment) =>
            assignment.batch_member_id.toString() === action.payload.toString()
        );
        return assignment;
      });
      state.filteredGoals = goals;
    },
    resetGoalsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGoalsRequest.pending, (state) => {
        state.goalsLoading = true;
      })
      .addCase(fetchAllGoalsRequest.fulfilled, (state, action) => {
        state.goalsLoading = false;
        state.allGoals = action.payload;
      })
      .addCase(fetchAllGoalsRequest.rejected, (state) => {
        state.goalsLoading = false;
      })
      .addCase(fetchGoalRequest.pending, (state) => {
        state.goalLoading = true;
      })
      .addCase(fetchGoalRequest.fulfilled, (state, action) => {
        state.goalLoading = false;
        state.currentGoal = action.payload;
      })
      .addCase(fetchGoalRequest.rejected, (state) => {
        state.goalLoading = false;
      })
      .addCase(fetchMilestoneRequest.pending, (state) => {
        state.milestoneLoading = true;
      })
      .addCase(fetchMilestoneRequest.fulfilled, (state, action) => {
        state.milestoneLoading = false;
        state.currentMilestone = action.payload;
      })
      .addCase(fetchMilestoneRequest.rejected, (state) => {
        state.milestoneLoading = false;
      })
      .addCase(createGoalRequest.pending, (state) => {
        state.goalLoading = false;
      })
      .addCase(createGoalRequest.fulfilled, (state, action) => {
        state.goalLoading = false;
        state.allGoals = [action.payload, ...state.allGoals];
      })
      .addCase(createGoalRequest.rejected, (state) => {
        state.goalLoading = false;
      })
      .addCase(updateGoalRequest.pending, (state) => {
        state.goalLoading = false;
      })
      .addCase(updateGoalRequest.fulfilled, (state, action) => {
        state.goalLoading = false;
        const updatedGoals = [...state.allGoals];
        const index = state.allGoals.findIndex(
          (goal) => goal.id.toString() === action.meta.arg.goalId.toString()
        );
        updatedGoals.splice(index, 1, action.payload);
        state.allGoals = updatedGoals;
      })
      .addCase(updateGoalRequest.rejected, (state) => {
        state.goalLoading = false;
      })
      .addCase(createMilestoneRequest.pending, (state) => {
        state.milestoneLoading = false;
      })
      .addCase(createMilestoneRequest.fulfilled, (state, action) => {
        if (state.currentGoal) {
          state.currentGoal = {
            ...state.currentGoal,
            milestones: [...state.currentGoal.milestones, action.payload],
          };
        }
        state.milestoneLoading = false;
      })
      .addCase(createMilestoneRequest.rejected, (state) => {
        state.milestoneLoading = false;
      })
      .addCase(deleteGoalRequest.pending, (state) => {
        state.goalLoading = false;
      })
      .addCase(deleteGoalRequest.fulfilled, (state, action) => {
        state.goalLoading = false;
        const goals = state.allGoals.filter(
          (goal) => goal.id !== action.meta.arg
        );
        state.allGoals = goals;
      })
      .addCase(deleteGoalRequest.rejected, (state) => {
        state.goalLoading = false;
      })
      .addCase(deleteMilestoneRequest.pending, (state) => {
        state.milestoneLoading = false;
      })
      .addCase(deleteMilestoneRequest.fulfilled, (state, action) => {
        state.milestoneLoading = false;
        if (state.currentGoal) {
          const milestones = state.currentGoal.milestones.filter(
            (milestone) => milestone.id !== action.meta.arg.id
          );
          state.currentGoal = {
            ...state.currentGoal,
            milestones,
          };
        }
      })
      .addCase(deleteMilestoneRequest.rejected, (state) => {
        state.milestoneLoading = false;
      })
      .addCase(createGoalAssignmentRequest.pending, (state) => {
        state.goalLoading = false;
      })
      .addCase(createGoalAssignmentRequest.fulfilled, (state, action) => {
        if (state.currentGoal) {
          if (state.currentGoal.id === action.meta.arg.goalId) {
            state.currentGoal.goal_assignments.push(action.payload);
          }
        }
        const updatedGoals = state.allGoals.map((goal) => {
          if (goal.id.toString() === action.meta.arg.goalId.toString()) {
            const updatedGoal = { ...goal };
            updatedGoal.goal_assignments.push(action.payload);
            return updatedGoal;
          }
          return goal;
        });
        state.allGoals = updatedGoals;
      })
      .addCase(createGoalAssignmentRequest.rejected, (state) => {
        state.goalLoading = false;
      })
      .addCase(deleteGoalAssignmentRequest.pending, (state) => {
        state.goalLoading = false;
      })
      .addCase(deleteGoalAssignmentRequest.fulfilled, (state, action) => {
        const updatedGoals = state.allGoals.map((goal) => {
          if (goal.id === action.meta.arg.goalId) {
            const updatedGoal = { ...goal };
            const index = goal.goal_assignments.findIndex(
              (member) => member.id === action.meta.arg.assigneeId
            );
            updatedGoal.goal_assignments.splice(index, 1);
            state.currentGoal = updatedGoal;
            return updatedGoal;
          }
          return goal;
        });
        state.allGoals = updatedGoals;
      })
      .addCase(deleteGoalAssignmentRequest.rejected, (state) => {
        state.goalLoading = false;
      });
  },
});

export const { filterGoals, resetGoalsState } = goalsSlice.actions;

export const selectAllGoals = (state: RootState) => state.goals.allGoals;
export const selectFilteredGoals = (state: RootState) =>
  state.goals.filteredGoals;
export const selectGoalsLoading = (state: RootState) =>
  state.goals.goalsLoading;
export const selectMilestonesLoading = (state: RootState) =>
  state.goals.milestonesLoading;
export const selectGoalLoading = (state: RootState) => state.goals.goalLoading;
export const selectMilestoneLoading = (state: RootState) =>
  state.goals.milestoneLoading;
export const selectCurrentGoal = (state: RootState) => state.goals.currentGoal;
export const selectCurrentMilestone = (state: RootState) =>
  state.goals.currentMilestone;

export default goalsSlice.reducer;
