import { axiosInstance } from '../api';

export const login = async (email: string, password: string) => {
  try {
    const response = await axiosInstance.post(`/auth/sign_in.json`, {
      email,
      password,
    });
    let userSession = {
      ...response.data,
      accessToken: response.headers['access-token'],
      client: response.headers['client'],
      expiry: response.headers['expiry'],
      uid: response.headers['uid'],
      tokenType: response.headers['token-type'],
    };

    return userSession;
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    await axiosInstance.delete(`/auth/sign_out`);
  } catch (error) {
    throw error;
  }
};
