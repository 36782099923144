import React from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { logoutRequest } from '../../redux/Users/UsersSlice';
import { LogoutOutlined } from '@ant-design/icons';
import { resetAllStates } from '../../redux/reducers';

interface Props {
  style: React.CSSProperties;
}

const LogoutLink: React.FC<Props> = ({ style }) => {
  const dispatch = useAppDispatch();

  const handleLogout = (e: any) => {
    e.preventDefault();
    dispatch(logoutRequest());
    dispatch(resetAllStates());
  };

  return (
    <div style={style}>
      <LogoutOutlined />
      <span style={{ marginLeft: 8 }}>
        <a type='text' onClick={handleLogout}>
          Logout
        </a>
      </span>
    </div>
  );
};

export default React.memo(LogoutLink);
