import React from 'react';
import { Spin } from 'antd';
import styles from './Loading.module.scss';

interface Props {
  loading: boolean;
  centered?: boolean;
}

const Loading: React.FC<Props> = ({ loading, centered }) => {
  if (centered) {
    return (
      <div className={styles['centered-container']}>
        {loading ? <Spin /> : null}
      </div>
    );
  }
  return loading ? <Spin size='small' /> : null;
};

export default Loading;
