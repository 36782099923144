import React from 'react';
import { BatchMemberType } from '../../types';
import { theme } from 'antd';
import { dasherize } from '../../utils/string';
import styles from './TaskAssignments.module.scss';

const { useToken } = theme;

interface Props {
  id: number;
  member: BatchMemberType;
  selectedParticipants: string[];
  membersId: string[];
  status?: string;
  addTaskAssignee: (id: string) => void;
  handleSelectMember: (participant: string) => void;
  deleteTaskAssignment: (id: string) => void;
}

const TaskAssignment: React.FC<Props> = ({
  id,
  member,
  selectedParticipants,
  status,
  addTaskAssignee,
  handleSelectMember,
  deleteTaskAssignment,
}) => {
  const { token } = useToken();

  return (
    <div key={id} className={styles['task-assignments']}>
      <div>
        <input
          className={styles['input']}
          type='checkbox'
          id={member.name}
          checked={selectedParticipants.includes(member.id)}
          onChange={() => {
            if (selectedParticipants.includes(member.id)) {
              handleSelectMember(member.id);
              deleteTaskAssignment(member.id);
            } else {
              handleSelectMember(member.id);
              addTaskAssignee(member.id);
            }
          }}
        />
        <label
          style={{ color: token.colorTextLabel, paddingLeft: '4px' }}
          htmlFor={member.name}
        >
          {member.name}{' '}
        </label>
      </div>
      {selectedParticipants.includes(member.id) && status && (
        <div
          className={`${styles['task-status']} ${styles[dasherize(status)]}`}
        ></div>
      )}
    </div>
  );
};

export default TaskAssignment;
