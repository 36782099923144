import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { ThunkAction, Action } from '@reduxjs/toolkit';
import { persistedReducer } from '../reduxPersist/redux-persist.config';
import rootReducer from './reducers';

const isDevToolsEnabled = () => process.env.NODE_ENV !== 'production';

const store = configureStore({
  reducer: persistedReducer || rootReducer,
  devTools: isDevToolsEnabled(),
});

const persistor = persistStore(store);

export { store, persistor };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
