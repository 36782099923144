import React, { FC, useState, useEffect } from 'react';
import { useAppSelector } from '../../redux/hooks';
import {
  BellOutlined,
  BookOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useBreakpoint } from './../../hooks/breakpoints';
import LogoutLink from '../LogoutLink/LogoutLink';
import Notifications from '../Notifications';
import { selectCurrentUser, selectIsAdmin } from '../../redux/Users/UsersSlice';
import styles from './Layout.module.scss';

const { Header, Sider, Content } = Layout;
const wideLayoutRoutes: string[] = ['/login', '/batch'];

const logoutStyle = {
  paddingLeft: 24,
  height: 40,
  lineHeight: '40px',
  marginInline: 4,
};

const route = `/notifications`;

const SiteLayout: FC = () => {
  const location = useLocation();
  const [showSider, setShowSider] = useState(false);
  const breakpoints: any = useBreakpoint();
  const [isWideLayout, setIsWideLayout] = useState<boolean>(false);
  const isAdmin = useAppSelector(selectIsAdmin);
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    let items = [
      {
        key: 'dashboard',
        icon: <HomeOutlined />,
        label: <Link to='/dashboard'>Dashboard</Link>,
      },
    ];

    if (isAdmin) {
      items.push(
        {
          key: 'spaces',
          icon: <BookOutlined />,
          label: <Link to='/spaces'>Spaces</Link>,
        },
        {
          key: 'batches',
          icon: <BookOutlined />,
          label: <Link to='/batches'>Batches</Link>,
        },
        {
          key: 'courses',
          icon: <BookOutlined />,
          label: <Link to='/courses'>Courses</Link>,
        }
      );
    }

    items.push({
      key: 'notifications',
      icon: <BellOutlined />,
      label: <Link to='/notifications'>Notifications</Link>,
    });
    setItems(items);
  }, []);

  useEffect(() => setShowSider(breakpoints.lg), [breakpoints]);

  useEffect(() => {
    setIsWideLayout(wideLayoutRoutes.includes(location.pathname));
  }, [location.pathname]);

  return (
    <>
      {!isWideLayout && (
        <Layout className={styles.HeaderLayout}>
          {showSider && (
            <Sider
              trigger={null}
              collapsible
              collapsed={false}
              theme='light'
              className={styles.HeaderLayout__sider}
            >
              <div className={styles.HeaderLayout__logo}>
                <span>Pseudosquare Training</span>
              </div>
              <Menu
                theme='light'
                mode='inline'
                defaultSelectedKeys={['1']}
                items={items}
              />
              <LogoutLink style={logoutStyle} />
            </Sider>
          )}
          <Layout className={styles.SiteLayout}>
            <Header
              className={styles.SiteLayout__header}
              style={{ padding: 0 }}
            >
              <div className={styles['header-notifications']}>
                {React.createElement(
                  showSider ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    style: {
                      fontSize: '16px',
                      lineHeight: '64px',
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    },
                    onClick: () => {
                      setShowSider(!showSider);
                    },
                  }
                )}
                <Notifications route={route} />
              </div>
            </Header>
            <Content className={styles.SiteLayout__background}>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      )}
      {isWideLayout && (
        <Content className={styles.SiteLayout__background}>
          <Outlet />
        </Content>
      )}
    </>
  );
};

export default SiteLayout;
