import React from 'react';
import Avatar from '../../../components/Avatar/Avatar';
import { TaskAssignmentsType } from '../../../types';
import { colors } from '../../../utils/constants';
import { createInitials } from '../../../utils/string';
import { UserAddOutlined } from '@ant-design/icons';

interface AssigneeListProps {
  taskAssignments: TaskAssignmentsType[];
}
const AssigneeList: React.FC<AssigneeListProps> = ({ taskAssignments }) => {
  return (
    <div>
      {Array.isArray(taskAssignments) && taskAssignments.length > 0 ? (
        <>
          {taskAssignments.map((taskAssignment) => {
            let colorIndex = 0;
            try {
              colorIndex = parseInt(taskAssignment.batch_member_id) % 10;
            } catch (e) {
              colorIndex = 0;
            }
            return (
              <Avatar
                key={taskAssignment.id}
                content={createInitials(taskAssignment.participant_name)}
                style={{
                  background: colors[colorIndex],
                }}
              />
            );
          })}
        </>
      ) : (
        <div>
          <UserAddOutlined
            style={{
              color: '#808080',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AssigneeList;
