import { axiosInstance } from '../api';

export const fetchBatchMember = async (memberId: string) => {
  const url = `/api/v1/batch_members/${memberId}.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchBatchMembers = async (batchId: string) => {
  const url = `/api/v1/batches/${batchId}/members.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createBatchMember = async (
  batchId: string,
  first_name: string,
  last_name: string,
  email: string
) => {
  const url = `/api/v1/batch_members.json`;
  const postData = {
    batch_member: {
      batch_id: batchId,
      first_name,
      last_name,
      email,
    },
  };
  try {
    const response = await axiosInstance.post(url, postData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
