import { axiosInstance } from '../api';

export const fetchSprints = async (projectId: string) => {
  const url = `/api/v1/projects/${projectId}/sprints.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCurrentSprint = async (sprintId: string) => {
  const url = `/api/v1/sprints/${sprintId}.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLastSprintOfProject = async (projectId: string) => {
  const url = `/api/v1/projects/${projectId}/last_sprint.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSprint = async (
  projectId: string,
  sprintCount: number,
  startDate: string,
  endDate: string
) => {
  const url = `/api/v1/sprints.json`;

  try {
    const response = await axiosInstance.post(url, {
      sprint: {
        sprint_count: sprintCount,
        project_id: projectId,
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSprint = async (sprintName: string, sprintId: string) => {
  const url = `/api/v1/sprints/${sprintId}.json`;

  try {
    const response = await axiosInstance.put(url, {
      project: {
        name: sprintName,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSprint = async (sprintId: string) => {
  const url = `/api/v1/sprints/${sprintId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
