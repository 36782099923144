import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  fetchBatches,
  fetchBatch,
  createBatch,
  updateBatch,
} from './BatchesAPI';
import { BatchType } from '../../types';

export interface Participants {
  allBatches: BatchType[];
  batchesLoading: boolean;
  page: number;
  totalCount: number;
  batch: BatchType | null;
  batchLoading: boolean;
  isBatchMember: boolean;
  errorMessage: string;
}

const initialState: Participants = {
  allBatches: [],
  batchesLoading: true,
  page: 1,
  totalCount: 0,
  batch: null,
  batchLoading: true,
  isBatchMember: false,
  errorMessage: '',
};

export const fetchBatchesRequest = createAsyncThunk(
  'batches/fetchBatches',
  async (page: number) => {
    const response = await fetchBatches(page);
    return response;
  }
);

export const fetchBatchRequest = createAsyncThunk(
  'batches/fetchBatch',
  async (paramsId: string, { dispatch, rejectWithValue }) => {
    dispatch(setBatchLoading(true));
    try {
      const response = await fetchBatch(paramsId);
      if (response.data) {
        dispatch(handelAuthorization(''));
        dispatch(setBatch(response.data));
        dispatch(setBatchLoading(false));
      }
      if (response.data.current_batch_member) {
        dispatch(updateMemberRole(true));
      } else {
        dispatch(updateMemberRole(false));
      }
    } catch (error: any) {
      if (error.response) {
        const statusCode = error.response.status;
        let errorMessage = '';

        if (statusCode === 401) {
          errorMessage = 'You do not have permission to access this page';
        } else if (statusCode === 404) {
          errorMessage = 'The requested resource was not found';
        } else {
          errorMessage = 'An error occurred while processing your request';
        }

        dispatch(handelAuthorization(errorMessage));
        dispatch(setBatchLoading(false));
        dispatch(updateMemberRole(false));
        return rejectWithValue(error.message);
      } else {
        dispatch(handelAuthorization('An error occurred'));
        dispatch(setBatchLoading(false));
        dispatch(updateMemberRole(false));
        return rejectWithValue('An error occurred');
      }
    }
  }
);

export const createBatchRequest = createAsyncThunk(
  'batches/createBatchRequest',
  async (attributes: any) => {
    const response = await createBatch(attributes);
    return response;
  }
);

export const updateBatchRequest = createAsyncThunk(
  'batches/updateBatchRequest',
  async (params: { attributes: any; batchId: string }) => {
    const { attributes, batchId } = params;
    const response = await updateBatch(attributes, batchId);
    return response;
  }
);

export const batchesSlice = createSlice({
  name: 'batches',
  initialState,
  reducers: {
    setBatchLoading: (state, action: PayloadAction<boolean>) => {
      state.batchLoading = action.payload;
    },
    setBatch: (state, action: PayloadAction<BatchType>) => {
      if (state.errorMessage.trim() === '') {
        state.batch = action.payload;
      }
    },
    handelAuthorization: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    updateMemberRole: (state, action: PayloadAction<boolean>) => {
      state.isBatchMember = action.payload;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetBatchesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBatchesRequest.pending, (state) => {
        state.batchesLoading = true;
      })
      .addCase(fetchBatchesRequest.fulfilled, (state, action) => {
        state.batchesLoading = false;
        state.allBatches = action.payload.batches;
        state.totalCount = action.payload.meta.total_entries || 0;
      })
      .addCase(fetchBatchRequest.rejected, (state) => {
        state.batchLoading = false;
      })
      .addCase(createBatchRequest.pending, (state) => {
        state.batchLoading = false;
      })
      .addCase(createBatchRequest.fulfilled, (state, action) => {
        state.batchLoading = false;
        state.allBatches = [action.payload, ...state.allBatches];
      })
      .addCase(createBatchRequest.rejected, (state) => {
        state.batchLoading = false;
      })
      .addCase(updateBatchRequest.pending, (state) => {
        state.batchLoading = false;
      })
      .addCase(updateBatchRequest.fulfilled, (state, action) => {
        state.batchLoading = false;
        const updatedBatches = [...state.allBatches];
        const index = state.allBatches.findIndex(
          (batch) => batch.id.toString() === action.meta.arg.batchId.toString()
        );
        updatedBatches.splice(index, 1, action.payload);
        state.allBatches = updatedBatches;
      })
      .addCase(updateBatchRequest.rejected, (state) => {
        state.batchLoading = false;
      });
  },
});

export const {
  updatePage,
  resetBatchesState,
  updateMemberRole,
  handelAuthorization,
  setBatchLoading,
  setBatch,
} = batchesSlice.actions;

export const selectAllBatches = (state: RootState) => state.batches.allBatches;
export const selectBatchesLoading = (state: RootState) =>
  state.batches.batchesLoading;
export const selectTotalCount = (state: RootState) => state.batches.totalCount;
export const selectPage = (state: RootState) => state.batches.page;
export const selectBatch = (state: RootState) => state.batches.batch;
export const selectBatchLoading = (state: RootState) =>
  state.batches.batchLoading;
export const selectIsBatchMember = (state: RootState) =>
  state.batches.isBatchMember;
export const selectErrorMessage = (state: RootState) =>
  state.batches.errorMessage;

export default batchesSlice.reducer;
