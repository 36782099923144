import React from "react";
import styles from './TaskTabs.module.scss';

interface Props {
  detailsHidden: boolean;
  commentsHidden: boolean;
  onHideDetails: () => void;
  onHideComments: () => void;
}

const TaskTabs: React.FC<Props> = ({
  detailsHidden,
  commentsHidden,
  onHideDetails,
  onHideComments
}) => {
  return (
    <div className={styles['tabs-container']}>
      <div className={styles['details-tab']}>
        <button
          onClick={onHideDetails}
          style={{ color: detailsHidden ? '#7b68ee' : '#a5a9b0' }}>
          Details
        </button>
      </div>
      <div className={styles['activity-tab']}>
        <button
          onClick={onHideComments}
          style={{ color: commentsHidden ? '#7b68ee' : '#a5a9b0' }}>
          Activity
        </button>
      </div>
    </div>
  )
}

export default TaskTabs