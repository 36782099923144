import { axiosInstance } from '../api';

export const fetchProjects = async (batchId: string) => {
  const url = `/api/v1/batches/${batchId}/projects.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProjectDetails = async (projectId: string) => {
  const url = `/api/v1/projects/${projectId}.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProject = async (batchId: string, projectName: string) => {
  const url = `/api/v1/projects.json`;

  try {
    const response = await axiosInstance.post(url, {
      project: {
        name: projectName,
        batch_id: batchId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProject = async (projectName: string, projectId: string) => {
  const url = `/api/v1/projects/${projectId}.json`;

  try {
    const response = await axiosInstance.put(url, {
      project: {
        name: projectName,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProject = async (projectId: string) => {
  const url = `/api/v1/projects/${projectId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllProjectMembers = async (projectId: string) => {
  const url = `/api/v1/projects/${projectId}/project_members.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProjectMemberDetails = async (memberId: string) => {
  const url = `/api/v1/project_members/${memberId}.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProjectMember = async (
  projectId: string,
  participantId: string
) => {
  const url = `/api/v1/project_members.json`;

  try {
    const response = await axiosInstance.post(url, {
      project_member: {
        project_id: projectId,
        batch_member_id: participantId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProjectMember = async (
  memberId: string,
  projectId: string
) => {
  const url = `/api/v1/project_members/${memberId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
