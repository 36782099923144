import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.css';

interface Props {
  value: string;
  style?: React.CSSProperties;
  placeholder?: string;
  module?: boolean;
  onInput: (value: string) => void;
  onSend: () => void;
  onModule?: (value: boolean) => void;
}

const RichTextEditor: React.FC<Props> = ({
  value,
  style,
  module,
  placeholder,
  onInput,
  onSend,
  onModule,
}) => {
  const editor = useRef<HTMLDivElement>(null);
  const textEditor = useRef<ReactQuill>(null);

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'blockquote',
    'code-block',
    // 'clean',
    'mention',
  ];

  const modules = {
    toolbar:
      [
        'bold',
        'italic',
        'underline',
        { list: 'ordered' },
        { list: 'bullet' },
        'blockquote',
        'code-block',
      ]
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (editor.current && !editor.current.contains(event.target as Node)) {
      onModule?.(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (textEditor.current) {
      textEditor.current.focus();
    }
  }, [module]);

  const handleQuillChange = (content: string) => {
    const cleanedContent = content.replace(/<p><br><\/p>/g, '');
    onInput(cleanedContent);
  };

  return (
    <div className='container' ref={editor} >
      {module &&
        <ReactQuill
          ref={textEditor}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          theme='snow'
          value={value}
          onChange={handleQuillChange}
        />}
      <div className={module ? 'editor-footer' : 'hide-edit-footer'} onClick={() => onModule?.(true)}>
        {!module &&
          <div className='placeholder'>
            <span>{placeholder}</span>
          </div>
        }
        <Button
          type='primary'
          icon={<SendOutlined />}
          className={`${!value || value === '' ? 'send-btn' : ''}`}
          disabled={!value || value === ''}
          onClick={onSend}
        ></Button>
      </div>
    </div >
  )
}

export default RichTextEditor