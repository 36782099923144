import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { fetchAllNotifications, updateNotifications } from './NotificationAPI';
import { NotificationType, QuotesType } from '../../types';

const motivationalQuotes = [
  { id: 1, quote: "Believe you can and you're halfway there." },
  {
    id: 2,
    quote: "Your time is limited, don't waste it living someone else's life.",
  },
  { id: 3, quote: 'The only way to do great work is to love what you do.' },
  {
    id: 4,
    quote:
      'Success is not final, failure is not fatal: It is the courage to continue that counts.',
  },
  { id: 5, quote: "Don't watch the clock; do what it does. Keep going." },
  {
    id: 6,
    quote:
      'The future belongs to those who believe in the beauty of their dreams.',
  },
  {
    id: 7,
    quote:
      'The only person you are destined to become is the person you decide to be.',
  },
  { id: 8, quote: "Don't let yesterday take up too much of today." },
  {
    id: 9,
    quote:
      'The only limit to our realization of tomorrow will be our doubts of today.',
  },
  {
    id: 10,
    quote: 'You are never too old to set another goal or to dream a new dream.',
  },
  { id: 11, quote: 'The harder the struggle, the more glorious the triumph.' },
  {
    id: 12,
    quote:
      'What you get by achieving your goals is not as important as what you become by achieving your goals.',
  },
  {
    id: 13,
    quote:
      'Success is walking from failure to failure with no loss of enthusiasm.',
  },
  {
    id: 14,
    quote:
      'Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.',
  },
  { id: 15, quote: "Set your goals high, and don't stop till you get there." },
  { id: 16, quote: 'Your only limit is your mind.' },
  { id: 17, quote: "Don't stop when you're tired. Stop when you're done." },
  {
    id: 18,
    quote:
      'Challenges are what make life interesting and overcoming them is what makes life meaningful.',
  },
  { id: 19, quote: 'Success is not in what you have, but who you are.' },
  {
    id: 20,
    quote:
      'Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got this!',
  },
];

export interface Notifications {
  allNotifications: NotificationType[];
  notificationsLoading: boolean;
  isOpen: boolean;
  count: number;
  quotes: QuotesType[];
}

const initialState: Notifications = {
  allNotifications: [],
  notificationsLoading: true,
  isOpen: false,
  count: 0,
  quotes: motivationalQuotes,
};

export const fetchNotificationsRequest = createAsyncThunk(
  'notifications/fetchNotificationsRequest',
  async (userId: string) => {
    const response = await fetchAllNotifications(userId);
    return response;
  }
);

export const updateNotificationsRequest = createAsyncThunk(
  'notifications/updateNotificationsRequest',
  async (params: { userId: string; notificationId: string }) => {
    const { userId, notificationId } = params;
    const response = await updateNotifications(notificationId, userId);
    return response;
  }
);

export const NotificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetNotificationsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsRequest.pending, (state) => {
        state.notificationsLoading = true;
      })
      .addCase(fetchNotificationsRequest.fulfilled, (state, action) => {
        state.notificationsLoading = false;
        const notifications = action.payload.notifications.filter(
          (notification: NotificationType) => notification.opened_at === null
        );
        state.allNotifications = notifications;
        state.count = state.allNotifications.length;
      })
      .addCase(fetchNotificationsRequest.rejected, (state) => {
        state.notificationsLoading = false;
      })
      .addCase(updateNotificationsRequest.pending, (state) => {
        state.notificationsLoading = false;
      })
      .addCase(updateNotificationsRequest.fulfilled, (state, action) => {
        state.notificationsLoading = false;
        const updatedNotifications = state.allNotifications.filter(
          (notification) => notification.id !== action.meta.arg.notificationId
        );
        state.allNotifications = updatedNotifications;
        state.count = state.allNotifications.length;
      })
      .addCase(updateNotificationsRequest.rejected, (state) => {
        state.notificationsLoading = false;
      });
  },
});

export const { resetNotificationsState } = NotificationsSlice.actions;

export const selectAllNotifications = (state: RootState) =>
  state.notifications.allNotifications;
export const selectNotificationsCount = (state: RootState) =>
  state.notifications.count;
export const selectNotificationsLoading = (state: RootState) =>
  state.notifications.notificationsLoading;
export const selectQuote = (state: RootState) => state.notifications.quotes;

export default NotificationsSlice.reducer;
