import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateTaskRequest } from '../../redux/Tasks/TasksSlice';
import { CaretRightOutlined } from '@ant-design/icons';
import { TaskType } from '../../types';
import { Popover } from 'antd';
import { dasherize } from '../../utils/string';
import styles from './TaskStatus.module.scss';
import { selectIsBatchMember } from '../../redux/Batches/BatchesSlice';

interface TaskStatusProps {
  task?: TaskType;
  visible?: boolean;
}

const TaskStatus: React.FC<TaskStatusProps> = ({ task, visible }) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<string>('');
  const dispatch = useAppDispatch();
  const isBatchMember = useAppSelector(selectIsBatchMember);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  useEffect(() => {
    if (task?.status) setStatus(task?.status);
  }, [task]);

  const handleStatus = (status: string) => {
    setStatus(status);
    setOpen(false);
    if (task && status) {
      dispatch(
        updateTaskRequest({
          key: 'status',
          value: status,
          taskId: task.id,
          projectId: task.project_id,
        })
      );
    }
  };

  const statusContent = () => (
    <div className={styles['status-content']}>
      <a
        className={styles['completed']}
        onClick={() => handleStatus('completed')}
      >
        COMPLETED
      </a>
      <a
        className={styles['in-review']}
        onClick={() => handleStatus('in_review')}
      >
        IN REVIEW
      </a>
      <a
        className={styles['in-progress']}
        onClick={() => handleStatus('in_progress')}
      >
        IN PROGRESS
      </a>
      <a className={styles['to-do']} onClick={() => handleStatus('to_do')}>
        TO DO
      </a>
    </div>
  );

  return (
    <div className={styles['task-details']}>
      <Popover
        placement='bottomLeft'
        content={statusContent}
        trigger='click'
        open={open}
        onOpenChange={handleOpenChange}
      >
        {visible && isBatchMember && (
          <div
            className={`${styles['task-details-status']} ${
              status ? styles[dasherize(status)] : styles['default']
            }`}
          >
            <span
              className={`${
                status === 'to_do'
                  ? styles['task-status-to-do']
                  : styles['task-status-title']
              }`}
            >
              {dasherize(status)}
            </span>
          </div>
        )}
        {!visible && (
          <div
            className={`${styles['status']} ${
              status ? styles[dasherize(status)] : styles['default']
            }`}
          ></div>
        )}
      </Popover>
      {visible && isBatchMember && (
        <div
          className={`${styles['task-details-next']} ${
            status ? styles[dasherize(status)] : styles['default']
          }`}
          onClick={() => {
            status === 'to_do'
              ? handleStatus('in_progress')
              : status === 'in_progress'
              ? handleStatus('in_review')
              : status === 'in_review'
              ? handleStatus('completed')
              : handleStatus('to_do');
          }}
        >
          <CaretRightOutlined
            className={`${
              status === 'to_do'
                ? styles['task-status-to-do']
                : styles['task-status-title']
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default TaskStatus;
