import React, { useState } from 'react';
import { message } from 'antd';
import { ChecklistType } from '../../../types';
import { PlusOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import RichInput from '../../RichInput';
import styles from './ChecklistItemForm.module.scss';
import { createChecklistItemRequest } from '../../../redux/Tasks/TasksSlice';

interface ChecklistItemFormProps {
  projectId: string;
  taskId: string;
  checklist: ChecklistType;
}

const itemStyle: React.CSSProperties = {
  padding: 0,
  cursor: 'pointer',
  minWidth: 300,
};

const ChecklistItemForm: React.FC<ChecklistItemFormProps> = ({
  projectId,
  taskId,
  checklist,
}) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>();

  const createChecklistItem = async (value: string) => {
    if (value) {
      await dispatch(
        createChecklistItemRequest({
          projectId,
          taskId,
          checklistId: checklist.id,
          description: value,
        })
      );
      setValue('');
      message.success('Action item created');
    }
  };

  const handleInput = (event: React.FormEvent<HTMLDivElement>) => {
    const element = event.currentTarget;
    setValue(element.textContent || '');
  };

  const handleBlur = async (event: any) => {
    await createChecklistItem(value || '');
    const element = event.target;
    element.innerHTML = '';
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await createChecklistItem(value || '');
      const element = event.target as any;
      element.innerHTML = '';
    }
  };

  return (
    <div className={styles['new-item']}>
      <PlusOutlined style={{ marginRight: 8, color: '#808080', fontSize: 14 }} />
      <RichInput
        isOpen={true}
        initialValue={''}
        value={value}
        onInput={handleInput}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        style={itemStyle}
        placeholder='New checklist item'
        autoFocus={false}
        placeholderStyle={{ fontSize: 14, lineHeight: '1.5', paddingLeft: 4 }}
      />
    </div>
  );
};

export default ChecklistItemForm;
