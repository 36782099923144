import React from 'react';
import { Avatar } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { ProjectMemberType } from '../../../types';

interface Props {
  projectMembers: ProjectMemberType[];
}

const ProjectMembers: React.FC<Props> = ({ projectMembers }) => {
  return (
    <a>
      {projectMembers && projectMembers.length > 0 ? (
        <Avatar.Group
          maxPopoverTrigger='focus'
          maxCount={2}
          maxStyle={{ color: '#fff', backgroundColor: '#bfbfbf' }}
        >
          {projectMembers.map((member, i) => {
            let initials = '';
            if (member.participant_name) {
              const [firstName, lastName] = member.participant_name.split(' ');
              const lastInitial =
                lastName && lastName.trim() ? lastName.charAt(0) : '';
              const firstInitial =
                firstName && firstName.trim() ? firstName.charAt(0) : '';
              initials = firstInitial + lastInitial;
            }
            return (
              <Avatar style={{ backgroundColor: '#bfbfbf' }} key={i} gap={8}>
                {initials}
              </Avatar>
            );
          })}
        </Avatar.Group>
      ) : (
        <Avatar icon={<UserAddOutlined />} gap={8} />
      )}
    </a>
  );
};

export default ProjectMembers;
