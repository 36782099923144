import { axiosInstance } from '../api';
import { TimeEntryType } from '../../types';

export const fetchTimeEntries = async (
  page: number,
  participantId: string,
  date?: string
) => {
  let url = '';
  date
    ? (url = `/api/v1/time_entries.json?page=${page}&batch_member_id=${participantId}&date=${date}`)
    : (url = `/api/v1/time_entries.json?page=${page}&batch_member_id=${participantId}`);

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRunningTimeEntry = async (participantId: string) => {
  const url = `/api/v1/time_entries/running.json?batch_member_id=${participantId}`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTotalTime = async (participantId: string) => {
  const url = `/api/v1/time_entries/total?batch_member_id=${participantId}`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createTimeEntry = async (
  timeEntry: Partial<TimeEntryType>,
  participantId: string
) => {
  const url = `/api/v1/time_entries.json`;
  let timeEntryAttributes = {
    ...timeEntry,
    batch_member_id: participantId,
  };

  try {
    const response = await axiosInstance.post(url, {
      time_entry: timeEntryAttributes,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTimeEntry = async (attributes: any, timeEntryId: string) => {
  const url = `/api/v1/time_entries/${timeEntryId}.json`;

  try {
    const response = await axiosInstance.put(url, {
      time_entry: { ...attributes },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTimeEntry = async (timeEntryId: string) => {
  const url = `/api/v1/time_entries/${timeEntryId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTimeEntryResources = async (timeEntryId: string) => {
  const url = `/api/v1/time_entries/${timeEntryId}/time_entry_resources.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
