import React from 'react';
import { ChecklistType } from '../../types';
import Checklist from './components/Checklist';

interface TaskChecklistsProps {
  projectId: string;
  taskId: string;
  checklists: ChecklistType[];
}

const TaskChecklists: React.FC<TaskChecklistsProps> = ({
  projectId,
  taskId,
  checklists,
}) => {
  return (
    <React.Fragment>
      {Array.isArray(checklists) &&
        checklists.map((checklist) => (
          <Checklist
            projectId={projectId}
            taskId={taskId}
            checklist={checklist}
            key={checklist.id}
          />
        ))}
    </React.Fragment>
  );
};

export default TaskChecklists;
