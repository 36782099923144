export const BASE_URL = process.env.REACT_APP_API_URL;

export const queries = {
  xs: '(min-width: 480px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  xxl: '(min-width: 1600px)',
  or: '(orientation: portrait)',
};

export const TASK_STATUSES = [
  { label: 'To Do', value: 'to_do' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'In Review', value: 'in_review' },
  { label: 'Completed', value: 'completed' },
];

export const colors = [
  'hsl(180 90% 50% / 75%)',
  'hsl(324 90% 50% / 75%)',
  'hsl(155 90% 50% / 75%)',
  'hsl(0 90% 50% / 75%)',
  'hsl(36 90% 50% / 75%)',
  'hsl(216 90% 50% / 75%)',
  'hsl(72 90% 50% / 75%)',
  'hsl(251 90% 50% / 75%)',
  'hsl(288 90% 50% / 75%)',
  'hsl(108 90% 50% / 75%)',
];
