import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  BatchType,
  SpaceType,
  SpaceMembersType,
  BatchMemberType,
} from '../../types';
import {
  fetchAllSpaces,
  fetchBatchesParticipants,
  createSpace,
  fetchSpace,
  updateSpace,
  deleteSpace,
  fetchSpaceBatches,
  fetchSpaceMembers,
  createSpaceMember,
  deleteSpaceMember,
} from './SpacesAPI';
import { handelAuthorization } from '../Batches/BatchesSlice';
import { RootState } from '../store';

export interface SpacesStateType {
  allSpaces: SpaceType[];
  spacesLoading: boolean;
  currentSpace: SpaceType | null;
  spaceBatches: BatchType[];
  spaceBatchesLoading: boolean;
  spaceMembers: SpaceMembersType[];
  spaceMembersLoading: boolean;
  batchesParticipants: BatchMemberType[];
  spaceLoading: boolean;
  spaceErrorMessage: string;
}

const initialState: SpacesStateType = {
  allSpaces: [],
  spacesLoading: false,
  currentSpace: null,
  spaceLoading: false,
  spaceBatches: [],
  spaceBatchesLoading: false,
  spaceMembers: [],
  spaceMembersLoading: false,
  batchesParticipants: [],
  spaceErrorMessage: '',
};

export const fetchAllSpacesRequest = createAsyncThunk(
  'spaces/fetchAllSpacesRequest',
  async () => {
    const response = await fetchAllSpaces();
    return response;
  }
);

export const fetchSpaceRequest = createAsyncThunk(
  'spaces/fetchSpaceRequest',
  async (id: string, { dispatch, rejectWithValue }) => {
    dispatch(setSpaceLoading(true));
    try {
      const response = await fetchSpace(id);
      if (response.data) {
        dispatch(handelAuthorization(''));
        dispatch(setSpace(response.data));
        dispatch(setSpaceLoading(false));
      }
    } catch (error: any) {
      if (error.response) {
        const statusCode = error.response.status;
        let errorMessage = '';

        if (statusCode === 401) {
          errorMessage = 'You do not have permission to access this page';
        } else if (statusCode === 404) {
          errorMessage = 'The requested resource was not found';
        } else {
          errorMessage = 'An error occurred while processing your request';
        }

        dispatch(handelAuthorization(errorMessage));
        dispatch(setSpaceLoading(false));
        return rejectWithValue(error.message);
      } else {
        dispatch(handelAuthorization('An error occurred'));
        dispatch(setSpaceLoading(false));
        return rejectWithValue('An error occurred');
      }
    }
  }
);

export const fetchSpaceBatchesRequest = createAsyncThunk(
  'spaces/fetchSpaceBatchesRequest',
  async (id: string) => {
    const response = await fetchSpaceBatches(id);
    return response;
  }
);

export const fetchSpaceMembersRequest = createAsyncThunk(
  'spaces/fetchSpaceMembersRequest',
  async (id: string) => {
    const response = await fetchSpaceMembers(id);
    return response;
  }
);

export const createSpaceMemberRequest = createAsyncThunk(
  'spaces/createSpaceMemberRequest',
  async (attributes: any) => {
    const response = await createSpaceMember(attributes);
    return response;
  }
);

export const fetchBathesParticipantsRequest = createAsyncThunk(
  'spaces/fetchBathesParticipantsRequest',
  async (id: string) => {
    const response = await fetchBatchesParticipants(id);
    return response;
  }
);

export const createSpaceRequest = createAsyncThunk(
  'spaces/createSpaceRequest',
  async (params: { name: string; organizationId: string }) => {
    const { name, organizationId } = params;
    const response = await createSpace(name, organizationId);
    return response;
  }
);

export const updateSpaceRequest = createAsyncThunk(
  'spaces/updateSpaceRequest',
  async (params: { attributes: any; spaceId: string }) => {
    const { attributes, spaceId } = params;
    const response = await updateSpace(attributes, spaceId);
    return response;
  }
);

export const deleteSpaceRequest = createAsyncThunk(
  'space/deleteSpaceRequest',
  async (id: string) => {
    const response = await deleteSpace(id);
    return response;
  }
);

export const deleteSpaceMemberRequest = createAsyncThunk(
  'space/deleteSpaceMemberRequest',
  async (id: string) => {
    const response = await deleteSpaceMember(id);
    return response;
  }
);

export const spacesSlice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
    setSpaceLoading: (state, action: PayloadAction<boolean>) => {
      state.spaceLoading = action.payload;
    },
    setSpace: (state, action: PayloadAction<SpaceType>) => {
      if (state.spaceErrorMessage.trim() === '') {
        state.currentSpace = action.payload;
      }
    },
    resetSpacesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSpacesRequest.pending, (state) => {
        state.spacesLoading = true;
      })
      .addCase(fetchAllSpacesRequest.fulfilled, (state, action) => {
        state.spacesLoading = false;
        state.allSpaces = action.payload;
      })
      .addCase(fetchAllSpacesRequest.rejected, (state) => {
        state.spacesLoading = false;
      })
      .addCase(fetchSpaceBatchesRequest.pending, (state) => {
        state.spaceBatchesLoading = true;
      })
      .addCase(fetchSpaceBatchesRequest.fulfilled, (state, action) => {
        state.spaceBatchesLoading = false;
        state.spaceBatches = action.payload;
      })
      .addCase(fetchSpaceBatchesRequest.rejected, (state) => {
        state.spaceBatchesLoading = false;
      })
      .addCase(fetchSpaceMembersRequest.pending, (state) => {
        state.spaceMembersLoading = true;
      })
      .addCase(fetchSpaceMembersRequest.fulfilled, (state, action) => {
        state.spaceMembersLoading = false;
        state.spaceMembers = action.payload.space_members;
      })
      .addCase(fetchSpaceMembersRequest.rejected, (state) => {
        state.spaceMembersLoading = false;
      })
      .addCase(fetchBathesParticipantsRequest.pending, (state) => {
        state.spaceMembersLoading = true;
      })
      .addCase(fetchBathesParticipantsRequest.fulfilled, (state, action) => {
        state.spaceMembersLoading = false;
        state.batchesParticipants = action.payload;
      })
      .addCase(fetchBathesParticipantsRequest.rejected, (state) => {
        state.spaceMembersLoading = false;
      })
      .addCase(createSpaceRequest.pending, (state) => {
        state.spacesLoading = false;
      })
      .addCase(createSpaceRequest.fulfilled, (state, action) => {
        state.spacesLoading = false;
        state.allSpaces = [action.payload, ...state.allSpaces];
      })
      .addCase(createSpaceRequest.rejected, (state) => {
        state.spacesLoading = false;
      })
      .addCase(createSpaceMemberRequest.pending, (state) => {
        state.spacesLoading = false;
      })
      .addCase(createSpaceMemberRequest.fulfilled, (state, action) => {
        state.spacesLoading = false;
        state.spaceMembers = [action.payload, ...state.spaceMembers];
      })
      .addCase(createSpaceMemberRequest.rejected, (state) => {
        state.spacesLoading = false;
      })
      .addCase(updateSpaceRequest.pending, (state) => {
        state.spacesLoading = false;
      })
      .addCase(updateSpaceRequest.fulfilled, (state, action) => {
        state.spacesLoading = false;
        const updatedSpaces = [...state.allSpaces];
        const index = state.allSpaces.findIndex(
          (space) => space.id.toString() === action.meta.arg.spaceId.toString()
        );
        updatedSpaces.splice(index, 1, action.payload);
        state.allSpaces = updatedSpaces;
      })
      .addCase(updateSpaceRequest.rejected, (state) => {
        state.spacesLoading = false;
      })
      .addCase(deleteSpaceRequest.pending, (state) => {
        state.spacesLoading = false;
      })
      .addCase(deleteSpaceRequest.fulfilled, (state, action) => {
        state.spacesLoading = false;
        const spaces = state.allSpaces.filter(
          (space) => space.id !== action.meta.arg
        );
        state.allSpaces = spaces;
      })
      .addCase(deleteSpaceRequest.rejected, (state) => {
        state.spacesLoading = false;
      })
      .addCase(deleteSpaceMemberRequest.pending, (state) => {
        state.spacesLoading = false;
      })
      .addCase(deleteSpaceMemberRequest.fulfilled, (state, action) => {
        state.spacesLoading = false;
        const spaces = state.spaceMembers.filter(
          (member) => member.id !== action.meta.arg
        );
        state.spaceMembers = spaces;
      })
      .addCase(deleteSpaceMemberRequest.rejected, (state) => {
        state.spacesLoading = false;
      });
  },
});
export const { resetSpacesState, setSpaceLoading, setSpace } =
  spacesSlice.actions;

export const selectAllSpaces = (state: RootState) => state.spaces.allSpaces;
export const selectSpacesLoading = (state: RootState) =>
  state.spaces.spacesLoading;
export const selectSpaceLoading = (state: RootState) =>
  state.spaces.spaceLoading;
export const selectCurrentSpace = (state: RootState) =>
  state.spaces.currentSpace;
export const selectSpaceBatches = (state: RootState) =>
  state.spaces.spaceBatches;
export const selectSpaceBathesLoading = (state: RootState) =>
  state.spaces.spaceBatchesLoading;
export const selectSpaceMembers = (state: RootState) =>
  state.spaces.spaceMembers;
export const selectSpaceMembersLoading = (state: RootState) =>
  state.spaces.spaceMembersLoading;
export const selectBatchesParticipants = (state: RootState) =>
  state.spaces.batchesParticipants;
export const selectSpaceErrorMessage = (state: RootState) =>
  state.spaces.spaceErrorMessage;

export default spacesSlice.reducer;
