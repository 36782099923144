import { axiosInstance } from '../api';

export const fetchLatestProgressReport = async (
  participantId: string,
  date: string
) => {
  const url = `/api/v1/latest_progress_report.json?batch_member_id=${participantId}&date=${date}`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProgressReportDetails = async (progressId: string) => {
  const url = `/api/v1/progress_reports/${progressId}.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllProgressReports = async (
  batchId: string,
  page: number,
  perPage: number
) => {
  const url = `/api/v1/batches/${batchId}/progress_reports.json?page=${page}&per_page=${perPage}`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProgressReport = async (
  date: string,
  description: string,
  participantId: string
) => {
  const url = `/api/v1/progress_reports.json`;

  try {
    const response = await axiosInstance.post(url, {
      progress_report: {
        date,
        description,
        batch_member_id: participantId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProgressReport = async (
  description: string,
  progressId: string
) => {
  const url = `/api/v1/progress_reports/${progressId}.json`;

  try {
    const response = await axiosInstance.put(url, {
      progress_report: {
        description,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProgressReport = async (progressId: string) => {
  const url = `/api/v1/progress_reports/${progressId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllProgressComments = async (progressId: string) => {
  const url = `/api/v1/progress_reports/${progressId}/comments.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProgressComment = async (
  progressId: string,
  userId: string,
  newComment: string
) => {
  const url = `/api/v1/comments.json`;

  const comment = {
    content: newComment,
    commentable_id: progressId,
    commentable_type: 'ProgressReport',
    user_id: userId,
  };

  try {
    const response = await axiosInstance.post(url, comment);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProgressComment = async (
  commentId: string,
  value: string
) => {
  const url = `/api/v1/comments/${commentId}.json`;
  const comment = {
    content: value,
  };

  try {
    const response = await axiosInstance.put(url, comment);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProgressComment = async (commentId: string) => {
  const url = `/api/v1/comments/${commentId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
