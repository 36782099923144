import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import Split from 'react-split';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import styles from './TaskDetailsModal.module.scss';
import TaskComments from '../TaskComments/TaskComments';
import { useBreakpoint } from '../../hooks/breakpoints';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  updateTaskRequest,
  fetchTaskDetailsRequest,
  selectTaskDetails,
  selectTaskDetailsLoading,
  fetchAllCommentsRequest,
  selectAllComments,
} from '../../redux/Tasks/TasksSlice';
import TaskTabs from '../TaskTabs';
import TaskDetails from '../TaskDetails';
import { selectBatch } from '../../redux/Batches/BatchesSlice';
import { RightOutlined } from '@ant-design/icons';
import {
  fetchProjectDetailsRequest,
  selectProjectDetails,
  selectProjectDetailsLoading,
} from '../../redux/Projects/ProjectsSlice';
import { selectBatchMember } from '../../redux/BatchMembers/BatchMembersSlice';
import { selectCurrentGoal } from '../../redux/Goals/GoalsSlice';
import Loading from '../Loading/Loading';

const TaskDetailsModal = () => {
  const breakpoints: any = useBreakpoint();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isEdited, setIsEdited] = useState('');
  const batch = useAppSelector(selectBatch);
  const batchMember = useAppSelector(selectBatchMember);
  const currentTask = useAppSelector(selectTaskDetails);
  const isLoading = useAppSelector(selectTaskDetailsLoading);
  const comments = useAppSelector(selectAllComments);
  const project = useAppSelector(selectProjectDetails);
  const projectLoading = useAppSelector(selectProjectDetailsLoading);
  const [detailsHidden, setDetailsHidden] = useState(true);
  const [commentsHidden, setCommentsHidden] = useState(true);
  const [tabsHidden, setTabsHidden] = useState(false);
  const [visible, setVisible] = useState(true);
  const currentGoal = useAppSelector(selectCurrentGoal);

  useEffect(() => {
    if (params.taskId) {
      dispatch(fetchTaskDetailsRequest(params.taskId));
      dispatch(fetchAllCommentsRequest(params.taskId));
    }
    if (params.projectId) {
      dispatch(fetchProjectDetailsRequest(params.projectId));
    } else if (state) {
      dispatch(fetchProjectDetailsRequest(state.projectId));
    }
  }, [params.taskId]);

  useEffect(() => {
    if (currentTask) {
      if (currentTask.description) {
        setDescription(currentTask.description);
      }
      if (currentTask.description === null) {
        setDescription('');
      }
      setTitle(currentTask.title);
    }
  }, [currentTask]);

  useEffect(() => {
    if (tabsHidden) {
      setCommentsHidden(false);
    }
  }, [tabsHidden]);

  useEffect(() => {
    if (!breakpoints.lg) {
      setTabsHidden(true);
    } else {
      setTabsHidden(false);
      setCommentsHidden(true);
      setDetailsHidden(true);
    }
  }, [breakpoints]);

  useEffect(() => {
    const handleResize = () => {
      if (!breakpoints.lg) {
        setTabsHidden(true);
      } else {
        setTabsHidden(false);
        setCommentsHidden(true);
        setDetailsHidden(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoints]);

  if (!batch) {
    return <Navigate to='/dashboard' replace />;
  }

  const onBlur = () => {
    if (isEdited === 'title') {
      if (title && currentTask?.title !== title && currentTask) {
        const taskId = currentTask.id;
        const value = title;
        const key = 'title';
        dispatch(
          updateTaskRequest({
            key,
            taskId,
            value,
            projectId: currentTask.project_id,
          })
        );
        setIsEdited('');
      }
    }
    if (isEdited === 'description') {
      if (
        description &&
        currentTask?.description !== description &&
        currentTask
      ) {
        const taskId = currentTask.id;
        const value = description;
        const key = 'description';
        dispatch(
          updateTaskRequest({
            key,
            taskId,
            value,
            projectId: currentTask.project_id,
          })
        );
        setIsEdited('');
      }
    }
  };

  const onTitleInput = (e: any) => {
    const content = e.target.innerHTML;
    if (content && content.toString() !== '<br>') {
      setTitle(content);
      setIsEdited('title');
    } else {
      setTitle('');
    }
  };

  const onDescriptionInput = (e: any) => {
    const content = e.target.innerHTML;
    if (content && content.toString() !== '<br>') {
      setDescription(content);
      setIsEdited('description');
    } else {
      setDescription('');
    }
  };
  const onHideDetails = () => {
    setDetailsHidden(true);
    setCommentsHidden(false);
  };

  const onHideComments = () => {
    setCommentsHidden(true);
    setDetailsHidden(false);
  };

  const onClose = () => {
    setVisible(false);

    if (params.goalId) {
      navigate(`/batch/${batch.id}/goals/${currentGoal?.id}`);
    } else if (params.projectId) {
      navigate(
        `/batch/${batch.id}/projects/${
          currentTask?.project_id
        }?sprintId=${searchParams.get('sprintId')}`
      );
    } else navigate(`/batch/${batch.id}/projects`);
  };

  return (
    <Modal
      className={styles['task-details']}
      bodyStyle={{ height: 'calc(100vh - 120px)' }}
      title={
        !isLoading &&
        !projectLoading && (
          <div className={styles['batch-heading']}>
            <Link to={`/batch/${batch?.id}`}>{batch?.name}</Link>
            <RightOutlined style={{ fontSize: '10px', color: '#7c828d' }} />
            <Link to={`/batch/${batch?.id}/projects/${project?.id}`}>
              {project?.name}
            </Link>
          </div>
        )
      }
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      {isLoading && projectLoading && (
        <Loading loading={isLoading} centered={true} />
      )}
      {!isLoading &&
        !projectLoading &&
        (tabsHidden ? (
          <div
            className={styles['model-container']}
            style={{ height: tabsHidden ? 'calc(100% - 50px)' : '100%' }}
          >
            <TaskTabs
              detailsHidden={detailsHidden}
              commentsHidden={commentsHidden}
              onHideComments={onHideComments}
              onHideDetails={onHideDetails}
            />
            {detailsHidden && currentTask && (
              <TaskDetails
                title={title}
                batchMemberId={batchMember ? batchMember.id : ''}
                visible={visible}
                currentTask={currentTask}
                description={description}
                onBlur={onBlur}
                onClose={onClose}
                onTitleInput={onTitleInput}
                onDescriptionInput={onDescriptionInput}
              />
            )}
            {commentsHidden && (
              <div
                style={{
                  borderLeft: commentsHidden ? 'none' : '1px solid #e2e2e2',
                  height: '100%',
                }}
              >
                {currentTask && (
                  <TaskComments task={currentTask} comments={comments} />
                )}
              </div>
            )}
          </div>
        ) : (
          <Split
            sizes={[60, 40]}
            minSize={100}
            expandToMin={true}
            gutterSize={16}
            direction='horizontal'
            style={{ display: 'flex', height: '100%' }}
          >
            {currentTask && (
              <TaskDetails
                onClose={onClose}
                batchMemberId={batchMember ? batchMember.id : ''}
                title={title}
                visible={visible}
                currentTask={currentTask}
                description={description}
                onBlur={onBlur}
                onTitleInput={onTitleInput}
                onDescriptionInput={onDescriptionInput}
              />
            )}
            <div
              style={{
                borderLeft: commentsHidden ? '1px solid #e2e2e2' : 'none',
                padding: 16,
              }}
            >
              {currentTask && (
                <TaskComments task={currentTask} comments={comments} />
              )}
            </div>
          </Split>
        ))}
    </Modal>
  );
};

export default TaskDetailsModal;
