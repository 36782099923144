import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SprintType } from '../../types';
import {
  fetchSprints,
  fetchCurrentSprint,
  fetchLastSprintOfProject,
  createSprint,
  updateSprint,
  deleteSprint,
} from './SprintsAPI';
import isEmpty from 'lodash.isempty';

export interface Sprints {
  allSprints: SprintType[];
  sprintsByProjects: Record<string, any>;
  sprintsLoading: boolean;
  currentSprint: SprintType | null;
  currentSprintLoading: boolean;
}

const initialState: Sprints = {
  allSprints: [],
  sprintsByProjects: {},
  sprintsLoading: true,
  currentSprintLoading: true,
  currentSprint: null,
};

export const fetchSprintsRequest = createAsyncThunk(
  'sprints/fetchSprintsRequest',
  async (params: { projectId: string }) => {
    const response = fetchSprints(params.projectId);
    return response;
  }
);

export const fetchCurrentSprintRequest = createAsyncThunk(
  'sprints/fetchCurrentSprintRequest',
  async (sprintId: string) => {
    const response = fetchCurrentSprint(sprintId);
    return response;
  }
);

export const fetchLastSprintOfProjectRequest = createAsyncThunk(
  'sprints/fetchLastSprintOfProjectRequest',
  async (params: { projectId: string }) => {
    const response = fetchLastSprintOfProject(params.projectId);
    return response;
  }
);

export const createSprintRequest = createAsyncThunk(
  'sprints/createSprintsRequest',
  async (params: {
    projectId: string;
    sprintCount: number;
    startDate: string;
    endDate: string;
  }) => {
    const { sprintCount, projectId, startDate, endDate } = params;
    const response = createSprint(projectId, sprintCount, startDate, endDate);
    return response;
  }
);

export const updateSprintRequest = createAsyncThunk(
  'sprints/updateSprintsRequest',
  async (params: { sprintName: string; sprintId: string }) => {
    const { sprintName, sprintId } = params;
    const response = updateSprint(sprintName, sprintId);
    return response;
  }
);

export const deleteSprintRequest = createAsyncThunk(
  'sprints/deleteSprintsRequest',
  async (sprintId: string) => {
    const response = deleteSprint(sprintId);
    return response;
  }
);

export const sprintSlice = createSlice({
  name: 'sprints',
  initialState,
  reducers: {
    resetSprintsState: () => initialState,
    updateActiveSprint: (
      state,
      action: PayloadAction<{ projectId: string; sprint: SprintType }>
    ) => {
      const projectId = action.payload.projectId;
      const sprint = action.payload.sprint;
      state.sprintsByProjects[projectId] = {
        ...state.sprintsByProjects[projectId],
        activeSprint: sprint,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSprintsRequest.pending, (state) => {
        state.sprintsLoading = true;
      })
      .addCase(fetchSprintsRequest.fulfilled, (state, action) => {
        state.sprintsLoading = false;
        const projectId = action.meta.arg.projectId;
        let sprintsData = {
          sprints: action.payload,
        };
        state.sprintsByProjects[projectId] = sprintsData;
        if (
          state.sprintsByProjects[projectId] &&
          isEmpty(state.sprintsByProjects[projectId].activeSprint)
        ) {
          const activeSprint = state.sprintsByProjects[projectId].sprints.find(
            (sprint: SprintType) => sprint.is_current
          );
          state.sprintsByProjects[projectId]['activeSprint'] = activeSprint;
        }
      })
      .addCase(fetchSprintsRequest.rejected, (state) => {
        state.sprintsLoading = false;
      })
      .addCase(fetchCurrentSprintRequest.pending, (state) => {
        state.currentSprintLoading = true;
      })
      .addCase(fetchCurrentSprintRequest.fulfilled, (state, action) => {
        state.currentSprintLoading = false;
        state.currentSprint = action.payload;
      })
      .addCase(fetchCurrentSprintRequest.rejected, (state) => {
        state.currentSprintLoading = false;
      })
      .addCase(createSprintRequest.pending, (state) => {
        state.sprintsLoading = false;
      })
      .addCase(createSprintRequest.fulfilled, (state, action) => {
        state.sprintsLoading = false;
        const projectId = action.meta.arg.projectId;
        let sprintsData = {
          sprints: [
            ...(state.sprintsByProjects[projectId].sprints || []),
            ...action.payload,
          ],
        };
        state.sprintsByProjects[projectId] = sprintsData;
      })
      .addCase(createSprintRequest.rejected, (state) => {
        state.sprintsLoading = false;
      })
      .addCase(updateSprintRequest.pending, (state) => {
        state.sprintsLoading = false;
      })
      .addCase(updateSprintRequest.fulfilled, (state, action) => {
        const updatedSprints = [...state.allSprints];
        const index = state.allSprints.findIndex(
          (sprint) => sprint.id === action.payload.id
        );
        updatedSprints.splice(index, 1, action.payload);
        state.allSprints = [...updatedSprints];
      })
      .addCase(updateSprintRequest.rejected, (state) => {
        state.sprintsLoading = false;
      })
      .addCase(deleteSprintRequest.pending, (state) => {
        state.sprintsLoading = false;
      })
      .addCase(deleteSprintRequest.fulfilled, (state, action) => {
        const updatedSprints = state.allSprints.filter(
          (sprint) => sprint.id !== action.meta.arg
        );
        state.allSprints = updatedSprints;
      })
      .addCase(deleteSprintRequest.rejected, (state) => {
        state.sprintsLoading = false;
      });
  },
});

export const { updateActiveSprint, resetSprintsState } = sprintSlice.actions;

export const selectSprintsByProjects = (state: RootState) =>
  state.sprints.sprintsByProjects;
export const selectSprintsLoading = (state: RootState) =>
  state.sprints.sprintsLoading;
export const selectCurrentSprint = (state: RootState) =>
  state.sprints.currentSprint;
export const selectCurrentSprintLoading = (state: RootState) =>
  state.sprints.currentSprintLoading;

export default sprintSlice.reducer;
