import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { login, logout } from './UsersAPI';
import { IUserSession } from '../../types';

export interface UserState {
  requesting: boolean;
  currentUser: IUserSession | null;
  isAdmin: boolean;
  isTeacher: boolean;
  isStudent: boolean;
}

const initialState: UserState = {
  requesting: false,
  currentUser: null,
  isAdmin: false,
  isTeacher: false,
  isStudent: false,
};

export const loginRequest = createAsyncThunk(
  'users/login',
  async (params: { email: string; password: string }) => {
    const { email, password } = params;
    const response = await login(email, password);
    return response;
  }
);

export const logoutRequest = createAsyncThunk('users/logout', async () => {
  await logout();
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsersState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginRequest.pending, (state) => {
        state.requesting = true;
      })
      .addCase(loginRequest.fulfilled, (state, action) => {
        state.requesting = false;
        state.currentUser = action.payload;
        state.isAdmin = action.payload.current_role.role === 'administrator';
        state.isTeacher = action.payload.current_role.role === 'teacher';
        state.isStudent = action.payload.current_role.role === 'student';
      })
      .addCase(loginRequest.rejected, (state) => {
        state.requesting = false;
      })
      .addCase(logoutRequest.pending, (state) => {
        state.requesting = true;
      })
      .addCase(logoutRequest.fulfilled, (state, action) => {
        state.requesting = false;
        state.currentUser = null;
        state.isAdmin = false;
        state.isTeacher = false;
        state.isStudent = false;
      })
      .addCase(logoutRequest.rejected, (state) => {
        state.requesting = false;
      });
  },
});

export const { resetUsersState } = usersSlice.actions;

export const selectLoggingIn = (state: RootState) => state.users.requesting;
export const selectLoggingOut = (state: RootState) => state.users.requesting;
export const selectCurrentUser = (state: RootState) => state.users.currentUser;
export const selectIsStudent = (state: RootState) => state.users.isStudent;
export const selectIsAdmin = (state: RootState) => state.users.isAdmin;
export const selectIsTeacher = (state: RootState) => state.users.isTeacher;

export default usersSlice.reducer;
