import React from 'react';
import { Checkbox } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Actions from '../../Actions';
import { useAppDispatch } from '../../../redux/hooks';
import {
  deleteChecklistItemRequest,
  updateChecklistItemRequest,
} from '../../../redux/Tasks/TasksSlice';
import { ChecklistItemType } from '../../../types';
import styles from './ChecklistItem.module.scss';

interface ChecklistItemProps {
  projectId: string;
  taskId: string;
  checklistId: string;
  checklistItem: ChecklistItemType;
}

const ChecklistItem: React.FC<ChecklistItemProps> = ({
  projectId,
  taskId,
  checklistId,
  checklistItem,
}) => {
  const dispatch = useAppDispatch();

  const handleCheck = (event: any) => {
    const isCompleted = event.target.checked;
    dispatch(
      updateChecklistItemRequest({
        projectId,
        taskId,
        checklistId,
        checklistItemId: checklistItem.id,
        checklistItem: {
          is_completed: isCompleted,
        },
      })
    );
  };

  const handleDeleteChecklistItem = () => {
    dispatch(
      deleteChecklistItemRequest({
        projectId,
        taskId,
        checklistId,
        checklistItemId: checklistItem.id,
      })
    );
  };

  return (
    <div className={`${styles['checklist-item']}`}>
      <Checkbox
        checked={checklistItem.is_completed}
        onChange={handleCheck}
        className={`${styles['checkbox']} ${
          checklistItem.is_completed ? styles.completed : ''
        }`}
      >
        {checklistItem.description}
      </Checkbox>
      <div className={styles.actions}>
        <Actions
          options={[
            {
              label: 'Delete',
              icon: <DeleteOutlined style={{ color: '#bc4841' }} />,
              handleAction: handleDeleteChecklistItem,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ChecklistItem;
