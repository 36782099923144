import { axiosInstance } from '../api';

export const fetchChannels = async (spaceId: string, userId: string) => {
  const url = `/chat/api/v1/chat_channels.json?space_id=${spaceId}&user_id=${userId}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchChannel = async (channelId: string) => {
  if (!channelId) return;

  const url = `/chat/api/v1/chat_channels/${channelId}.json`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createChannel = async (
  name: string,
  spaceId: string,
  isDirect: boolean,
  isPrivate: boolean,
  members: string[]
) => {
  const requestBody = {
    chat_channel: {
      name,
      chat_space_id: spaceId,
      is_direct: isDirect,
      is_private: isPrivate,
      members,
    },
  };
  const url = `/chat/api/v1/chat_channels.json`;
  const response = await axiosInstance.post(url, requestBody);
  return response.data;
};

export const createChannelMember = async (
  channelId: string,
  userId: string
) => {
  const requestBody = {
    channel_membership: {
      chat_channel_id: channelId,
      user_id: userId,
    },
  };
  const url = `/chat/api/v1/channel_memberships.json`;
  const response = await axiosInstance.post(url, requestBody);
  return response.data;
};

export const removeChannelMember = async (memberId: string) => {
  const url = `/chat/api/v1/channel_memberships/${memberId}.json`;
  const response = await axiosInstance.delete(url);
  return response.data;
};
