import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DailyTimeSummaryType } from '../../types';
import { RootState } from '../store';
import { fetchDailyTimeSummary } from './DailyTimeSummaryAPI';

interface DailyTimeSummary {
  dailyTimeSummary: DailyTimeSummaryType[];
  loading: boolean;
}

const initialState: DailyTimeSummary = {
  dailyTimeSummary: [],
  loading: false,
};

export const fetchDailyTimeSummaryRequest = createAsyncThunk(
  'dailyTimeSummary/fetchDailyTimeSummaryRequest',
  async (memberId: string) => {
    const response = await fetchDailyTimeSummary(memberId);
    return response;
  }
);

const dailyTimeSummary = createSlice({
  name: 'dailyTimeSummary',
  initialState,
  reducers: {
    resetDailyTimeSummaryState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyTimeSummaryRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDailyTimeSummaryRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.dailyTimeSummary = action.payload;
      })
      .addCase(fetchDailyTimeSummaryRequest.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { resetDailyTimeSummaryState } = dailyTimeSummary.actions;

export const selectDailyTimeSummary = (state: RootState) =>
  state.dailyTimeSummary.dailyTimeSummary;
export const selectLoading = (state: RootState) =>
  state.dailyTimeSummary.loading;

export default dailyTimeSummary.reducer;
