import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash.isempty';
import { RootState } from '../store';
import {
  CommentType,
  TaskType,
  TaskAssignmentsType,
  ChecklistType,
  ChecklistItemType,
} from '../../types';
import {
  fetchAllTasks,
  fetchTasksDetails,
  fetchAllComments,
  createTask,
  createComment,
  updateTask,
  updateComment,
  deleteTask,
  deleteComment,
  deleteTaskAssignment,
  createTaskAssignment,
  updateTaskAssignment,
  createChecklist,
  deleteChecklist,
  createChecklistItem,
  updateChecklistItem,
  deleteChecklistItem,
} from './TasksAPI';

export interface Tasks {
  allTasks: TaskType[];
  tasksByProjects: any;
  tasksLoading: boolean;
  completedTasks: TaskType[];
  inProgressTasks: TaskType[];
  inReviewTasks: TaskType[];
  toDoTasks: TaskType[];
  showAllTask: string;
  selectedUserId: string;
  taskDetails: TaskType | null;
  taskDetailsLoading: boolean;
  allComments: CommentType[];
  loadingChecklist: boolean;
  loadingChecklistItem: boolean;
}

const initialState: Tasks = {
  allTasks: [],
  tasksByProjects: {},
  tasksLoading: true,
  completedTasks: [],
  inProgressTasks: [],
  inReviewTasks: [],
  toDoTasks: [],
  showAllTask: 'myTasks',
  selectedUserId: '',
  taskDetails: null,
  taskDetailsLoading: true,
  allComments: [],
  loadingChecklist: false,
  loadingChecklistItem: false,
};

export const fetchAllTasksRequest = createAsyncThunk(
  'tasks/fetchAllTasksRequest',
  async (params: { projectId: string; sprintId?: string }) => {
    const { projectId, sprintId } = params;
    const response = await fetchAllTasks(projectId, sprintId);
    return response;
  }
);

export const fetchTaskDetailsRequest = createAsyncThunk(
  'tasks/fetchTaskDetailsRequest',
  async (taskId: string) => {
    const response = await fetchTasksDetails(taskId);
    return response;
  }
);

export const fetchAllCommentsRequest = createAsyncThunk(
  'tasks/fetchAllCommentsRequest',
  async (taskId: string) => {
    const response = await fetchAllComments(taskId);
    return response;
  }
);

export const createTaskRequest = createAsyncThunk(
  'tasks/createTaskRequest',
  async (params: {
    title: string;
    projectId: string;
    assignee: string[];
    sprintId: number | null;
    status: string;
  }) => {
    const { title, projectId, assignee, sprintId, status } = params;
    const response = await createTask(
      title,
      projectId,
      assignee,
      sprintId,
      status
    );
    return response;
  }
);

export const createCommentRequest = createAsyncThunk(
  'tasks/createCommentRequest',
  async (params: { taskId: string; userId: string; newComment: string }) => {
    const { taskId, userId, newComment } = params;
    const response = await createComment(taskId, userId, newComment);
    return response;
  }
);

export const updateTaskRequest = createAsyncThunk(
  'tasks/updateTaskRequest',
  async (params: {
    key: string;
    taskId: string;
    value: string;
    projectId: string;
  }) => {
    const { key, taskId, value } = params;
    const response = await updateTask(key, taskId, value);
    return response;
  }
);

export const updateCommentRequest = createAsyncThunk(
  'tasks/updateCommentRequest',
  async (params: { commentId: string; value: string }) => {
    const { commentId, value } = params;
    const response = await updateComment(commentId, value);
    return response;
  }
);

export const deleteTaskRequest = createAsyncThunk(
  'tasks/deleteTaskRequest',
  async (params: { id: string; projectId: string }) => {
    const response = await deleteTask(params.id);
    return response;
  }
);

export const deleteTaskAssignmentRequest = createAsyncThunk(
  'tasks/deleteTaskAssignmentRequest',
  async (params: { id: string; taskId: string; projectId: string }) => {
    const response = await deleteTaskAssignment(params.id);
    return response;
  }
);

export const deleteCommentRequest = createAsyncThunk(
  'tasks/deleteCommentRequest',
  async (commentId: string) => {
    const response = await deleteComment(commentId);
    return response;
  }
);

export const createTaskAssignmentRequest = createAsyncThunk(
  'tasks/createTaskAssignment',
  async (params: {
    taskId: string;
    participantId: string;
    projectId: string;
  }) => {
    const { taskId, participantId } = params;
    const response = await createTaskAssignment(taskId, participantId);
    return response;
  }
);

export const updateTaskAssignmentRequest = createAsyncThunk(
  'tasks/updateTaskAssignmentRequest',
  async (params: {
    status: string;
    taskAssignmentId: string;
    taskId: string;
    projectId: string;
  }) => {
    const { status, taskAssignmentId } = params;
    const response = await updateTaskAssignment(status, taskAssignmentId);
    return response;
  }
);

export const createChecklistRequest = createAsyncThunk(
  'tasks/createChecklistRequest',
  async (params: { projectId: string; taskId: string }) => {
    const response = await createChecklist(params);
    return response;
  }
);

export const deleteChecklistRequest = createAsyncThunk(
  'tasks/deleteChecklistRequest',
  async (params: {
    projectId: string;
    taskId: string;
    checklistId: string;
  }) => {
    const response = await deleteChecklist(params);
    return response;
  }
);

export const createChecklistItemRequest = createAsyncThunk(
  'tasks/createChecklistItemRequest',
  async (params: {
    projectId: string;
    taskId: string;
    checklistId: string;
    description: string;
  }) => {
    const response = await createChecklistItem(params);
    return response;
  }
);

export const updateChecklistItemRequest = createAsyncThunk(
  'tasks/updateChecklistItemRequest',
  async (params: {
    projectId: string;
    taskId: string;
    checklistId: string;
    checklistItemId: string;
    checklistItem: Partial<ChecklistItemType>;
  }) => {
    const response = await updateChecklistItem(params);
    return response;
  }
);

export const deleteChecklistItemRequest = createAsyncThunk(
  'tasks/deleteChecklistItemRequest',
  async (params: {
    projectId: string;
    taskId: string;
    checklistId: string;
    checklistItemId: string;
  }) => {
    const response = await deleteChecklistItem(params);
    return response;
  }
);

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    resetTasksState: () => initialState,
    onChangeShowAllTask: (state, action: PayloadAction<string>) => {
      state.showAllTask = action.payload;
    },
    setSelectedUserId: (state, action: PayloadAction<string>) => {
      state.selectedUserId = action.payload;
    },
    onFilterTasks: (state, action: PayloadAction<string>) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTasksRequest.pending, (state) => {
        state.tasksLoading = true;
      })
      .addCase(fetchAllTasksRequest.fulfilled, (state, action) => {
        state.tasksLoading = false;
        const projectId = action.meta.arg.projectId;
        state.tasksByProjects = state.tasksByProjects || {};
        state.tasksByProjects[projectId] = {
          tasks: action.payload,
        };
      })
      .addCase(fetchAllTasksRequest.rejected, (state) => {
        state.tasksLoading = false;
      })
      .addCase(createTaskRequest.pending, (state) => {
        state.tasksLoading = false;
      })
      .addCase(createTaskRequest.fulfilled, (state, action) => {
        state.tasksLoading = false;
        const projectId = action.meta.arg.projectId;
        const tasks = Array.isArray(state.tasksByProjects[projectId].tasks)
          ? [...state.tasksByProjects[projectId].tasks]
          : [];
        state.tasksByProjects[projectId] = {
          tasks: [...tasks, action.payload],
        };
      })
      .addCase(createTaskRequest.rejected, (state) => {
        state.tasksLoading = false;
      })
      .addCase(updateTaskRequest.pending, (state) => {
        state.tasksLoading = false;
      })
      .addCase(updateTaskRequest.fulfilled, (state, action) => {
        const projectId = action.meta.arg.projectId;
        const tasks = Array.isArray(state.tasksByProjects[projectId].tasks)
          ? [...state.tasksByProjects[projectId].tasks]
          : [];
        const index = tasks.findIndex((task) => task.id === action.payload.id);
        tasks.splice(index, 1, action.payload);
        state.tasksByProjects[projectId] = { tasks };
      })
      .addCase(updateTaskRequest.rejected, (state) => {
        state.tasksLoading = false;
      })
      .addCase(deleteTaskRequest.pending, (state) => {
        state.tasksLoading = false;
      })
      .addCase(deleteTaskRequest.fulfilled, (state, action) => {
        const projectId = action.meta.arg.projectId;
        let tasks = Array.isArray(state.tasksByProjects[projectId].tasks)
          ? [...state.tasksByProjects[projectId].tasks]
          : [];
        tasks = tasks.filter((task) => task.id !== action.meta.arg.id);
        state.tasksByProjects[projectId] = { tasks };
      })
      .addCase(deleteTaskRequest.rejected, (state) => {
        state.tasksLoading = false;
      })
      .addCase(createTaskAssignmentRequest.pending, (state) => {
        state.tasksLoading = false;
      })
      .addCase(createTaskAssignmentRequest.fulfilled, (state, action) => {
        if (!isEmpty(state.taskDetails)) {
          state.taskDetails.task_assignments = [
            ...state.taskDetails.task_assignments,
            action.payload,
          ];
        }
        const taskId = action.meta.arg.taskId;
        const projectId = action.meta.arg.projectId;
        let tasks = Array.isArray(state.tasksByProjects[projectId].tasks)
          ? [...state.tasksByProjects[projectId].tasks]
          : [];

        tasks = tasks.map((task) => {
          if (task.id.toString() === taskId.toString()) {
            const updatedTask = { ...task };
            updatedTask.task_assignments.push(action.payload);
            return updatedTask;
          }
          return task;
        });
        state.tasksByProjects[projectId] = { tasks };
      })
      .addCase(createTaskAssignmentRequest.rejected, (state) => {
        state.tasksLoading = false;
      })
      .addCase(fetchTaskDetailsRequest.pending, (state) => {
        state.taskDetailsLoading = true;
      })
      .addCase(fetchTaskDetailsRequest.fulfilled, (state, action) => {
        state.taskDetailsLoading = false;
        state.taskDetails = action.payload;
      })
      .addCase(fetchTaskDetailsRequest.rejected, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(fetchAllCommentsRequest.pending, (state) => {
        state.taskDetailsLoading = true;
      })
      .addCase(fetchAllCommentsRequest.fulfilled, (state, action) => {
        state.taskDetailsLoading = false;
        state.allComments = action.payload.comments;
      })
      .addCase(fetchAllCommentsRequest.rejected, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(createCommentRequest.pending, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(createCommentRequest.fulfilled, (state, action) => {
        state.taskDetailsLoading = false;
        state.allComments = [...state.allComments, action.payload];
      })
      .addCase(createCommentRequest.rejected, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(updateCommentRequest.pending, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(updateCommentRequest.fulfilled, (state, action) => {
        const updatedComments = [...state.allComments];
        const index = state.allComments.findIndex(
          (comment) => comment.id.toString() === action.payload.id.toString()
        );
        updatedComments.splice(index, 1, action.payload);
        state.allComments = updatedComments;
      })
      .addCase(updateCommentRequest.rejected, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(deleteCommentRequest.pending, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(deleteCommentRequest.fulfilled, (state, action) => {
        const updatedComments = state.allComments.filter(
          (comment) => comment.id !== action.meta.arg
        );
        state.allComments = updatedComments;
      })
      .addCase(deleteCommentRequest.rejected, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(deleteTaskAssignmentRequest.pending, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(deleteTaskAssignmentRequest.fulfilled, (state, action) => {
        const taskId = action.meta.arg.taskId;
        const projectId = action.meta.arg.projectId;
        let tasks = Array.isArray(state.tasksByProjects[projectId].tasks)
          ? [...state.tasksByProjects[projectId].tasks]
          : [];

        const index = tasks.findIndex(
          (task) => task.id.toString() === taskId.toString()
        );

        if (index >= 0 && tasks[index]) {
          const task = { ...tasks[index] };
          let taskAssignments: TaskAssignmentsType[] = task.task_assignments;
          task.task_assignments = taskAssignments.filter(
            (assignment) =>
              assignment.id.toString() !== action.meta.arg.id.toString()
          );
          tasks.splice(index, 1, task);
          state.tasksByProjects[projectId] = { tasks };
        }
      })
      .addCase(deleteTaskAssignmentRequest.rejected, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(updateTaskAssignmentRequest.pending, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(updateTaskAssignmentRequest.fulfilled, (state, action) => {
        const taskId = action.meta.arg.taskId;
        const projectId = action.meta.arg.projectId;
        let tasks = Array.isArray(state.tasksByProjects[projectId].tasks)
          ? [...state.tasksByProjects[projectId].tasks]
          : [];

        const index = tasks.findIndex(
          (task) => task.id.toString() === taskId.toString()
        );

        if (index >= 0 && tasks[index]) {
          const task = tasks[index];
          const taskAssignmentIndex = task.task_assignments.findIndex(
            (assignment: any) =>
              assignment.id.toString() ===
              action.meta.arg.taskAssignmentId.toString()
          );
          if (taskAssignmentIndex >= 0) {
            task.task_assignments[taskAssignmentIndex].status =
              action.payload.status;
            tasks.splice(index, 1, task);
          }
          state.tasksByProjects[projectId] = { tasks };
        }
      })
      .addCase(updateTaskAssignmentRequest.rejected, (state) => {
        state.taskDetailsLoading = false;
      })
      .addCase(createChecklistRequest.pending, (state) => {
        state.loadingChecklist = true;
      })
      .addCase(createChecklistRequest.fulfilled, (state, action) => {
        state.loadingChecklist = false;
        const taskId = action.meta.arg.taskId;
        const projectId = action.meta.arg.projectId;
        let tasks = Array.isArray(state.tasksByProjects[projectId].tasks)
          ? [...state.tasksByProjects[projectId].tasks]
          : [];

        const index = tasks.findIndex(
          (task) => task.id.toString() === taskId.toString()
        );
        if (index >= 0 && tasks[index]) {
          let task = tasks[index];
          task.checklists = task.checklists || [];
          task.checklists = [...task.checklists, action.payload];
          tasks.splice(index, 1, task);
          state.tasksByProjects[projectId] = { tasks };
        }
        let taskDetails = state.taskDetails;
        if (!isEmpty(taskDetails)) {
          let checklists = taskDetails.checklists || [];
          taskDetails.checklists = [...checklists, action.payload];
          state.taskDetails = taskDetails;
        }
      })
      .addCase(createChecklistRequest.rejected, (state) => {
        state.loadingChecklist = false;
      })
      .addCase(deleteChecklistRequest.pending, (state) => {
        state.loadingChecklist = true;
      })
      .addCase(deleteChecklistRequest.fulfilled, (state, action) => {
        state.loadingChecklist = false;
        const taskId = action.meta.arg.taskId;
        const projectId = action.meta.arg.projectId;
        let tasks = Array.isArray(state.tasksByProjects[projectId].tasks)
          ? [...state.tasksByProjects[projectId].tasks]
          : [];

        const index = tasks.findIndex(
          (task) => task.id.toString() === taskId.toString()
        );
        if (index >= 0 && tasks[index]) {
          let task = tasks[index];
          let checklists = task.checklists || [];
          task.checklists = checklists.filter(
            (checklist: ChecklistType) =>
              checklist.id !== action.meta.arg.checklistId
          );
          tasks.splice(index, 1, task);
          state.tasksByProjects[projectId] = { tasks };
        }
        let taskDetails = state.taskDetails;
        if (!isEmpty(taskDetails)) {
          let checklists = taskDetails.checklists || [];
          taskDetails.checklists = checklists.filter(
            (checklist: ChecklistType) =>
              checklist.id !== action.meta.arg.checklistId
          );
          state.taskDetails = taskDetails;
        }
      })
      .addCase(deleteChecklistRequest.rejected, (state) => {
        state.loadingChecklist = false;
      })
      .addCase(createChecklistItemRequest.pending, (state) => {
        state.loadingChecklistItem = true;
      })
      .addCase(createChecklistItemRequest.fulfilled, (state, action) => {
        state.loadingChecklistItem = false;
        let taskDetails = state.taskDetails;
        if (!isEmpty(taskDetails)) {
          let checklists = [...taskDetails.checklists] || [];
          checklists.map((checklist) => {
            if (checklist.id == action.meta.arg.checklistId) {
              let checklistItems = checklist.checklist_items || [];
              checklist.checklist_items = [...checklistItems, action.payload];
            }
          });
          taskDetails.checklists = checklists;
          state.taskDetails = taskDetails;
        }
      })
      .addCase(createChecklistItemRequest.rejected, (state) => {
        state.loadingChecklistItem = false;
      })
      .addCase(updateChecklistItemRequest.pending, (state) => {
        state.loadingChecklistItem = true;
      })
      .addCase(updateChecklistItemRequest.fulfilled, (state, action) => {
        state.loadingChecklistItem = false;
        let taskDetails = state.taskDetails;
        if (!isEmpty(taskDetails)) {
          let checklists = [...taskDetails.checklists] || [];
          checklists.map((checklist) => {
            if (checklist.id == action.meta.arg.checklistId) {
              let checklistItems = [...checklist.checklist_items] || [];
              let checklistItemIndex = checklistItems.findIndex(
                (checklistItem) =>
                  checklistItem.id === action.meta.arg.checklistItemId
              );
              if (
                checklistItemIndex >= 0 &&
                checklistItems[checklistItemIndex]
              ) {
                let checklistItem = checklistItems[checklistItemIndex];
                checklistItem = { ...checklistItem, ...action.payload };
                checklistItems.splice(checklistItemIndex, 1, checklistItem);
              }
              checklist.checklist_items = checklistItems;
            }
          });
          taskDetails.checklists = checklists;
          state.taskDetails = taskDetails;
        }
      })
      .addCase(updateChecklistItemRequest.rejected, (state) => {
        state.loadingChecklistItem = false;
      })
      .addCase(deleteChecklistItemRequest.pending, (state) => {
        state.loadingChecklistItem = true;
      })
      .addCase(deleteChecklistItemRequest.fulfilled, (state, action) => {
        state.loadingChecklistItem = false;
        let taskDetails = state.taskDetails;
        if (!isEmpty(taskDetails)) {
          let checklists = [...taskDetails.checklists] || [];
          checklists.map((checklist) => {
            if (checklist.id == action.meta.arg.checklistId) {
              let checklistItems = [...checklist.checklist_items] || [];
              checklistItems = checklistItems.filter(
                (checklistItem) =>
                  checklistItem.id !== action.meta.arg.checklistItemId
              );
              checklist.checklist_items = checklistItems;
            }
          });
          taskDetails.checklists = checklists;
          state.taskDetails = taskDetails;
        }
      })
      .addCase(deleteChecklistItemRequest.rejected, (state) => {
        state.loadingChecklistItem = false;
      });
  },
});

export const {
  onChangeShowAllTask,
  onFilterTasks,
  setSelectedUserId,
  resetTasksState,
} = tasksSlice.actions;

export const selectTasksByProjects = (state: RootState) =>
  state.tasks.tasksByProjects;
export const selectAllTasks = (state: RootState) => state.tasks.allTasks;
export const selectTasksLoading = (state: RootState) =>
  state.tasks.tasksLoading;
export const selectShowAllTask = (state: RootState) => state.tasks.showAllTask;
export const selectSelectedUserId = (state: RootState) =>
  state.tasks.selectedUserId;
export const selectTaskDetails = (state: RootState) => state.tasks.taskDetails;
export const selectTaskDetailsLoading = (state: RootState) =>
  state.tasks.taskDetailsLoading;
export const selectAllComments = (state: RootState) => state.tasks.allComments;
export const selectLoadingChecklist = (state: RootState) =>
  state.tasks.loadingChecklist;

export default tasksSlice.reducer;
