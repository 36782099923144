import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash.isempty';
import { RootState } from '../store';
import {
  fetchRunningTimeEntry,
  fetchTimeEntries,
  fetchTimeEntryResources,
  fetchTotalTime,
  createTimeEntry,
  updateTimeEntry,
  deleteTimeEntry,
} from './TimeEntriesAPI';
import {
  TimeEntryResourceType,
  TimeEntryType,
  TotalTimeEntryType,
} from '../../types';

const ZERO_DERATION: string = '00:00:00';
const Total_Time: TotalTimeEntryType = {
  today: ZERO_DERATION,
  yesterday: ZERO_DERATION,
  this_week: ZERO_DERATION,
  this_month: ZERO_DERATION,
};

export interface TimeEntriesState {
  allTimeEntries: TimeEntryType[];
  timeEntriesLoading: boolean;
  timerRunning: boolean;
  timeEntry: TimeEntryType | null;
  startMoment: string | null;
  totalTimeEntry: TotalTimeEntryType;
  stopMoment: string | null;
  page: number;
  timeEntryResources: TimeEntryResourceType[];
  loadingImages: boolean;
}

const initialState: TimeEntriesState = {
  allTimeEntries: [],
  timeEntriesLoading: false,
  timerRunning: false,
  timeEntry: null,
  startMoment: null,
  totalTimeEntry: Total_Time,
  stopMoment: null,
  page: 1,
  timeEntryResources: [],
  loadingImages: true,
};

export const fetchTimeEntriesRequest = createAsyncThunk(
  'timeEntries/fetchTimeEntriesRequest',
  async (params: { page: number; participantId: string; date?: string }) => {
    const { page, participantId, date } = params;
    const response = await fetchTimeEntries(page, participantId, date);
    return response;
  }
);

export const fetchRunningTimeEntryRequest = createAsyncThunk(
  'timeEntries/fetchRunningTimeEntryRequest',
  async (participantId: string) => {
    const response = await fetchRunningTimeEntry(participantId);
    return response;
  }
);

export const fetchTimeEntryResourcesRequest = createAsyncThunk(
  'timeEntries/fetchTimeEntryResourcesRequest',
  async (participantId: string) => {
    const response = await fetchTimeEntryResources(participantId);
    return response;
  }
);

export const fetchTotalTimeRequest = createAsyncThunk(
  'timeEntries/fetchTotalTimeRequest',
  async (participantId: string) => {
    const response = await fetchTotalTime(participantId);
    return response;
  }
);

export const createTimeEntryRequest = createAsyncThunk(
  'timeEntries/createTimeEntryRequest',
  async (params: {
    timeEntry: Partial<TimeEntryType>;
    participantId: string;
  }) => {
    const { timeEntry, participantId } = params;
    const response = await createTimeEntry(timeEntry, participantId);
    return response;
  }
);

export const updateRunningTimeEntryRequest = createAsyncThunk(
  'timeEntries/updateRunningTimeEntryRequest',
  async (params: { attributes: any; timeEntryId: string }) => {
    const { attributes, timeEntryId } = params;
    const response = await updateTimeEntry(attributes, timeEntryId);
    return response;
  }
);

export const updateTimeEntryRequest = createAsyncThunk(
  'timeEntries/updateTimeEntryRequest',
  async (params: { attributes: any; timeEntryId: string }) => {
    const { attributes, timeEntryId } = params;
    const response = await updateTimeEntry(attributes, timeEntryId);
    return response;
  }
);

export const deleteTimeEntryRequest = createAsyncThunk(
  'timeEntries/deleteTimeEntryRequest',
  async (timeEntryId: string) => {
    const response = await deleteTimeEntry(timeEntryId);
    return response;
  }
);

export const timeEntriesSlice = createSlice({
  name: 'timeEntriesSlice',
  initialState,
  reducers: {
    resetTimeEntriesState: () => initialState,
    loadMore: (state) => {
      state.page = state.page + 1;
    },
    setStartMoment: (state, action: PayloadAction<string>) => {
      state.startMoment = action.payload;
    },
    setStopMoment: (state, action: PayloadAction<string>) => {
      state.stopMoment = action.payload;
    },
    setTimerRunning: (state, action: PayloadAction<boolean>) => {
      state.timerRunning = action.payload;
    },
    updateDescription: (state, action: PayloadAction<any>) => {
      if (state.timeEntry) {
        state.timeEntry = { ...state.timeEntry, description: action.payload };
      } else {
        state.timeEntry = {
          id: '',
          duration: '',
          start: '',
          stop: '',
          description: action.payload,
        };
      }
    },
    handelUpdateTimeEntry: (state, action: PayloadAction<any>) => {
      state.timeEntry = {
        ...state.timeEntry,
        ...action.payload,
      };
    },
    clearTimerEntries: (state) => {
      state.allTimeEntries = [];
      state.timeEntry = null;
      state.startMoment = null;
      state.totalTimeEntry = Total_Time;
      state.timerRunning = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeEntriesRequest.pending, (state) => {
        state.timeEntriesLoading = true;
      })
      .addCase(fetchTimeEntriesRequest.fulfilled, (state, action) => {
        state.timeEntriesLoading = false;
        state.allTimeEntries = action.payload.time_entries;
      })
      .addCase(fetchTimeEntriesRequest.rejected, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(fetchTimeEntryResourcesRequest.pending, (state) => {
        state.loadingImages = true;
      })
      .addCase(fetchTimeEntryResourcesRequest.fulfilled, (state, action) => {
        state.loadingImages = false;
        state.timeEntryResources = action.payload;
      })
      .addCase(fetchTimeEntryResourcesRequest.rejected, (state) => {
        state.loadingImages = false;
      })
      .addCase(fetchRunningTimeEntryRequest.pending, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(fetchRunningTimeEntryRequest.fulfilled, (state, action) => {
        state.timerRunning = !isEmpty(action.payload);
        state.timeEntry = action.payload;
        state.startMoment = action.payload.start.toString();
        state.timeEntriesLoading = false;
      })
      .addCase(fetchRunningTimeEntryRequest.rejected, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(fetchTotalTimeRequest.pending, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(fetchTotalTimeRequest.fulfilled, (state, action) => {
        state.timeEntriesLoading = false;
        state.totalTimeEntry = action.payload;
      })
      .addCase(fetchTotalTimeRequest.rejected, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(createTimeEntryRequest.pending, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(createTimeEntryRequest.fulfilled, (state, action) => {
        state.timeEntriesLoading = false;
        state.timeEntry = {
          ...action.payload,
          start: action.payload.start,
        };
      })
      .addCase(createTimeEntryRequest.rejected, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(updateRunningTimeEntryRequest.pending, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(updateRunningTimeEntryRequest.fulfilled, (state, action) => {
        state.timeEntriesLoading = false;
        state.timeEntry = null;
        state.allTimeEntries = [action.payload, ...state.allTimeEntries];
        state.startMoment = null;
        state.stopMoment = null;
      })
      .addCase(updateRunningTimeEntryRequest.rejected, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(updateTimeEntryRequest.pending, (state) => {
        state.timeEntriesLoading = true;
      })
      .addCase(updateTimeEntryRequest.fulfilled, (state, action) => {
        state.timeEntriesLoading = false;
        const updatedTimeEntry = [...state.allTimeEntries];
        const index = state.allTimeEntries.findIndex(
          (entry) => entry.id === action.payload.id
        );
        updatedTimeEntry.splice(index, 1, action.payload);
        state.allTimeEntries = updatedTimeEntry;
      })
      .addCase(updateTimeEntryRequest.rejected, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(deleteTimeEntryRequest.pending, (state) => {
        state.timeEntriesLoading = false;
      })
      .addCase(deleteTimeEntryRequest.fulfilled, (state, action) => {
        const updatedTimeEntries = state.allTimeEntries.filter(
          (timeEntry) => timeEntry.id !== action.meta.arg
        );
        state.allTimeEntries = updatedTimeEntries;
      })
      .addCase(deleteTimeEntryRequest.rejected, (state) => {
        state.timeEntriesLoading = false;
      });
  },
});

export const {
  loadMore,
  setStartMoment,
  setStopMoment,
  setTimerRunning,
  handelUpdateTimeEntry,
  updateDescription,
  clearTimerEntries,
  resetTimeEntriesState,
} = timeEntriesSlice.actions;

export const selectAllTimeEntries = (state: RootState) =>
  state.timeEntries.allTimeEntries;
export const selectTimeEntriesLoading = (state: RootState) =>
  state.timeEntries.timeEntriesLoading;
export const selectTimeEntry = (state: RootState) =>
  state.timeEntries.timeEntry;
export const selectTimerRunning = (state: RootState) =>
  state.timeEntries.timerRunning;
export const selectStartMoment = (state: RootState) =>
  state.timeEntries.startMoment;
export const selectTotalTimeEntry = (state: RootState) =>
  state.timeEntries.totalTimeEntry;
export const selectStopMoment = (state: RootState) =>
  state.timeEntries.stopMoment;
export const selectPage = (state: RootState) => state.timeEntries.page;
export const selectLoadingImages = (state: RootState) =>
  state.timeEntries.loadingImages;
export const selectTimeEntryResources = (state: RootState) =>
  state.timeEntries.timeEntryResources;

export default timeEntriesSlice.reducer;
