import { axiosInstance } from '../api';

export const fetchAllNotifications = async (userId: string) => {
  const url = `/api/v2/users/${userId}/notifications.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateNotifications = async (
  notificationId: string,
  userId: string
) => {
  const url = `/api/v2/users/${userId}/notifications/${notificationId}/open.json`;

  try {
    const response = await axiosInstance.put(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
