import React from 'react';
import ChecklistItems from './ChecklistItems';
import Actions from '../../Actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  deleteChecklistRequest,
  selectLoadingChecklist,
} from '../../../redux/Tasks/TasksSlice';
import { ChecklistType } from '../../../types';
import { DeleteOutlined } from '@ant-design/icons';
import ChecklistItemForm from '../components/ChecklistItemForm';
import styles from './Checklist.module.scss';

interface ChecklistProps {
  projectId: string;
  taskId: string;
  checklist: ChecklistType;
}

const Checklist: React.FC<ChecklistProps> = ({
  projectId,
  taskId,
  checklist,
}) => {
  const dispatch = useAppDispatch();
  const deletingChecklist = useAppSelector(selectLoadingChecklist);
  const handleDeleteChecklist = () => {
    dispatch(
      deleteChecklistRequest({
        projectId: projectId,
        taskId: taskId,
        checklistId: checklist.id,
      })
    );
  };

  return (
    <div key={`checklist-${checklist.id}`} className={styles.checklist}>
      <div className={styles.header}>
        <div className={styles.title}>{checklist.title}</div>
        <Actions
          options={[
            {
              label: 'Delete',
              icon: <DeleteOutlined style={{ color: '#bc4841' }} />,
              handleAction: handleDeleteChecklist,
            },
          ]}
        />
      </div>
      <div className={styles['checklist-items']}>
        {/* {Array.isArray(checklist.checklist_items) &&
          checklist.checklist_items.length > 5 && (
            <ChecklistItemForm
              projectId={projectId}
              taskId={taskId}
              checklist={checklist}
            />
          )} */}
        <ChecklistItems
          checklistItems={checklist.checklist_items}
          projectId={projectId}
          taskId={taskId}
          checklistId={checklist.id}
        />
        <ChecklistItemForm
          projectId={projectId}
          taskId={taskId}
          checklist={checklist}
        />
      </div>
    </div>
  );
};

export default Checklist;
