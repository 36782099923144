import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { ConfigProvider } from 'antd';
import { blue, gray } from '@ant-design/colors';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { BreakpointProvider } from './hooks/breakpoints';
import { queries } from './utils/constants';
import setupAxiosInterceptors from './redux/api';
import './index.css';

const container = document.getElementById('root')!;
const root = createRoot(container);
setupAxiosInterceptors(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BreakpointProvider queries={queries}>
          <ConfigProvider
            theme={{
              token: {
                colorIcon: gray[8],
                colorPrimary: blue.primary,
                colorBgBase: '#fff',
                colorBgLayout: '#fff',
                colorLink: gray[6],
                colorLinkActive: blue[6],
                colorLinkHover: blue[4],
                colorTextHeading: gray[6],
              },
              components: {
                Progress: {
                  colorBgContainer: blue.primary,
                },
              },
            }}
          >
            <App />
          </ConfigProvider>
        </BreakpointProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
