import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CommentType, ProgressReportType } from '../../types';
import {
  fetchAllProgressReports,
  fetchProgressReportDetails,
  fetchLatestProgressReport,
  fetchAllProgressComments,
  createProgressReport,
  updateProgressReport,
  deleteProgressReport,
  createProgressComment,
  updateProgressComment,
  deleteProgressComment,
} from './ProgressReportsAPI';

export interface ProgressReports {
  latestProgressReport: ProgressReportType | null;
  progressLoading: boolean;
  allProgressReports: ProgressReportType[];
  allProgressLoading: boolean;
  totalCount: number;
  progressDescription: string;
  progressDetails: ProgressReportType | null;
  progressDetailsLoading: boolean;
  filteredReports: ProgressReportType[];
  page: number;
  perPage: number;
  allProgressComments: CommentType[];
}

const initialState: ProgressReports = {
  latestProgressReport: null,
  progressLoading: true,
  allProgressReports: [],
  allProgressLoading: true,
  totalCount: 0,
  progressDescription: '',
  progressDetails: null,
  filteredReports: [],
  progressDetailsLoading: true,
  page: 1,
  perPage: 10,
  allProgressComments: [],
};

export const fetchLatestProgressRequest = createAsyncThunk(
  'progressReport/fetchLatestProgressRequest',
  async (params: { participantId: string; date: string }) => {
    const { participantId, date } = params;
    const response = await fetchLatestProgressReport(participantId, date);
    return response;
  }
);

export const fetchAllProgressRequest = createAsyncThunk(
  'progressReport/fetchAllProgressRequest',
  async (params: { batchId: string; page: number; perPage: number }) => {
    const { batchId, page, perPage } = params;
    const response = await fetchAllProgressReports(batchId, page, perPage);
    return response;
  }
);

export const fetchAllProgressCommentsRequest = createAsyncThunk(
  'progressReport/fetchAllProgressCommentsRequest',
  async (progressId: string) => {
    const response = await fetchAllProgressComments(progressId);
    return response;
  }
);

export const createProgressRequest = createAsyncThunk(
  'progressReport/createProgressRequest',
  async (params: {
    date: string;
    description: string;
    participantId: string;
  }) => {
    const { description, participantId, date } = params;
    const response = await createProgressReport(
      date,
      description,
      participantId
    );
    return response;
  }
);

export const createProgressCommentRequest = createAsyncThunk(
  'progressReport/createProgressCommentRequest',
  async (params: {
    progressId: string;
    userId: string;
    newComment: string;
  }) => {
    const { progressId, userId, newComment } = params;
    const response = await createProgressComment(
      progressId,
      userId,
      newComment
    );
    return response;
  }
);

export const updateProgressRequest = createAsyncThunk(
  'progressReport/updateProgressRequest',
  async (params: { description: string; progressId: string }) => {
    const { description, progressId } = params;
    const response = await updateProgressReport(description, progressId);
    return response;
  }
);

export const updateProgressCommentRequest = createAsyncThunk(
  'progressReport/updateProgressCommentRequest',
  async (params: { commentId: string; value: string }) => {
    const { commentId, value } = params;
    const response = await updateProgressComment(commentId, value);
    return response;
  }
);

export const deleteProgressRequest = createAsyncThunk(
  'progressReport/deleteProgressRequest',
  async (progressId: string) => {
    const response = await deleteProgressReport(progressId);
    return response;
  }
);

export const deleteProgressCommentRequest = createAsyncThunk(
  'progressReport/deleteProgressCommentRequest',
  async (commentId: string) => {
    const response = await deleteProgressComment(commentId);
    return response;
  }
);

export const fetchProgressDetailsRequest = createAsyncThunk(
  'progressReport/fetchProgressDetailsRequest',
  async (progressId: string) => {
    const response = await fetchProgressReportDetails(progressId);
    return response;
  }
);

export const progressReportsSlice = createSlice({
  name: 'progressReports',
  initialState,
  reducers: {
    resetProgressReportsState: () => initialState,
    addDescription: (state, action: PayloadAction<string>) => {
      state.progressDescription = action.payload;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    filterProgressReport: (
      state,
      action: PayloadAction<{ participantId?: string; date?: string }>
    ) => {
      const { participantId, date } = action.payload;
      if (participantId && date) {
        const filteredReports = state.filteredReports.filter(
          (report) =>
            report.batch_member_id.toString() === participantId.toString() &&
            report.date.toString() === date.toString()
        );
        state.filteredReports = filteredReports;
      } else if (participantId) {
        const filteredReports = state.allProgressReports.filter(
          (report) =>
            report.batch_member_id.toString() === participantId.toString()
        );
        state.filteredReports = filteredReports;
      } else if (date) {
        const filteredReports = state.allProgressReports.filter(
          (report) => report.date.toString() === date.toString()
        );
        state.filteredReports = filteredReports;
      } else {
        state.filteredReports = state.allProgressReports;
      }
    },
    onClearReportFilter: (state) => {
      state.filteredReports = state.allProgressReports;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestProgressRequest.pending, (state) => {
        state.progressLoading = true;
      })
      .addCase(fetchLatestProgressRequest.fulfilled, (state, action) => {
        state.progressLoading = false;
        state.latestProgressReport = action.payload;
        state.progressDescription = action.payload.description;
      })
      .addCase(fetchLatestProgressRequest.rejected, (state) => {
        state.progressLoading = false;
      })
      .addCase(fetchProgressDetailsRequest.pending, (state) => {
        state.progressDetailsLoading = true;
      })
      .addCase(fetchProgressDetailsRequest.fulfilled, (state, action) => {
        state.progressDetailsLoading = false;
        state.progressDetails = action.payload;
      })
      .addCase(fetchProgressDetailsRequest.rejected, (state) => {
        state.progressDetailsLoading = false;
      })
      .addCase(fetchAllProgressRequest.pending, (state) => {
        state.allProgressLoading = true;
      })
      .addCase(fetchAllProgressRequest.fulfilled, (state, action) => {
        state.allProgressLoading = false;
        state.allProgressReports = action.payload.progress_reports;
        state.filteredReports = action.payload.progress_reports;
        state.totalCount = action.payload.meta.total_entries || 0;
      })
      .addCase(fetchAllProgressRequest.rejected, (state) => {
        state.allProgressLoading = false;
      })
      .addCase(createProgressRequest.pending, (state) => {
        state.allProgressLoading = false;
      })
      .addCase(createProgressRequest.fulfilled, (state, action) => {
        state.latestProgressReport = action.payload;
        state.allProgressReports = [
          action.payload,
          ...state.allProgressReports,
        ];
        state.filteredReports = state.allProgressReports;
        state.allProgressLoading = false;
      })
      .addCase(createProgressRequest.rejected, (state) => {
        state.allProgressLoading = false;
      })
      .addCase(updateProgressRequest.pending, (state) => {
        state.allProgressLoading = false;
      })
      .addCase(updateProgressRequest.fulfilled, (state, action) => {
        const updatedReports = [...state.allProgressReports];
        const index = state.allProgressReports.findIndex(
          (dailyReport) => dailyReport.uuid === action.payload.uuid
        );
        updatedReports.splice(index, 1, action.payload);
        state.allProgressReports = [...updatedReports];
        state.filteredReports = state.allProgressReports;
      })
      .addCase(updateProgressRequest.rejected, (state) => {
        state.allProgressLoading = false;
      })
      .addCase(deleteProgressRequest.pending, (state) => {
        state.allProgressLoading = false;
      })
      .addCase(deleteProgressRequest.fulfilled, (state, action) => {
        const updatedReports = state.allProgressReports.filter(
          (dailyReport) => dailyReport.uuid !== action.meta.arg
        );
        state.allProgressReports = updatedReports;
        state.filteredReports = state.allProgressReports;
      })
      .addCase(deleteProgressRequest.rejected, (state) => {
        state.allProgressLoading = false;
      })
      .addCase(fetchAllProgressCommentsRequest.pending, (state) => {
        state.progressLoading = false;
      })
      .addCase(fetchAllProgressCommentsRequest.fulfilled, (state, action) => {
        state.progressLoading = false;
        state.allProgressComments = action.payload.comments;
      })
      .addCase(fetchAllProgressCommentsRequest.rejected, (state) => {
        state.progressLoading = false;
      })
      .addCase(createProgressCommentRequest.pending, (state) => {
        state.progressDetailsLoading = false;
      })
      .addCase(createProgressCommentRequest.fulfilled, (state, action) => {
        state.progressDetailsLoading = false;
        state.allProgressComments = [
          ...state.allProgressComments,
          action.payload,
        ];
      })
      .addCase(createProgressCommentRequest.rejected, (state) => {
        state.progressDetailsLoading = false;
      })
      .addCase(updateProgressCommentRequest.pending, (state) => {
        state.progressDetailsLoading = false;
      })
      .addCase(updateProgressCommentRequest.fulfilled, (state, action) => {
        const updatedComments = [...state.allProgressComments];
        const index = state.allProgressComments.findIndex(
          (comment) => comment.id.toString() === action.payload.id.toString()
        );
        updatedComments.splice(index, 1, action.payload);
        state.allProgressComments = updatedComments;
      })
      .addCase(updateProgressCommentRequest.rejected, (state) => {
        state.progressDetailsLoading = false;
      })
      .addCase(deleteProgressCommentRequest.pending, (state) => {
        state.progressDetailsLoading = false;
      })
      .addCase(deleteProgressCommentRequest.fulfilled, (state, action) => {
        const updatedComments = state.allProgressComments.filter(
          (comment) => comment.id !== action.meta.arg
        );
        state.allProgressComments = updatedComments;
      })
      .addCase(deleteProgressCommentRequest.rejected, (state) => {
        state.progressDetailsLoading = false;
      });
  },
});

export const {
  addDescription,
  updatePage,
  filterProgressReport,
  onClearReportFilter,
  resetProgressReportsState,
} = progressReportsSlice.actions;

export const selectLatestProgressReport = (state: RootState) =>
  state.progressReports.latestProgressReport;
export const selectProgressLoading = (state: RootState) =>
  state.progressReports.progressLoading;
export const selectAllProgressReports = (state: RootState) =>
  state.progressReports.filteredReports;
export const selectAllProgressLoading = (state: RootState) =>
  state.progressReports.allProgressLoading;
export const selectTotalCount = (state: RootState) =>
  state.progressReports.totalCount;
export const selectProgressDescription = (state: RootState) =>
  state.progressReports.progressDescription;
export const selectPage = (state: RootState) => state.progressReports.page;
export const selectPerPage = (state: RootState) =>
  state.progressReports.perPage;
export const selectProgressDetails = (state: RootState) =>
  state.progressReports.progressDetails;
export const selectProgressDetailsLoading = (state: RootState) =>
  state.progressReports.progressDetailsLoading;
export const selectProgressComments = (state: RootState) =>
  state.progressReports.allProgressComments;

export default progressReportsSlice.reducer;
