import React from 'react';
import { ChecklistItemType } from '../../../types';
import ChecklistItem from './ChecklistItem';

interface ChecklistItemsProps {
  projectId: string;
  taskId: string;
  checklistId: string;
  checklistItems: ChecklistItemType[];
}

const ChecklistItems: React.FC<ChecklistItemsProps> = ({
  projectId,
  taskId,
  checklistId,
  checklistItems,
}) => {
  return (
    <React.Fragment>
      {Array.isArray(checklistItems) &&
        checklistItems.map((item) => (
          <ChecklistItem
            checklistItem={item}
            key={item.id}
            projectId={projectId}
            taskId={taskId}
            checklistId={checklistId}
          />
        ))}
    </React.Fragment>
  );
};

export default ChecklistItems;
