import { axiosInstance } from '../api';

export const fetchParticipantProgress = async (participantId: string) => {
  const url = `/api/v1/batch_member_progresses.json?batch_member_id=${participantId}`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllCourses = async () => {
  const url = `/api/v1/courses.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCourseModules = async (courseId: string) => {
  const url = `/api/v1/course_modules.json?course_id=${courseId}`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCourseProgress = async (
  participantId: string,
  topicId: string
) => {
  const url = `/api/v1/batch_member_progresses.json`;

  const batch_member_progress = {
    topic_id: topicId,
    batch_member_id: participantId,
  };

  try {
    const response = await axiosInstance.post(url, batch_member_progress);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCourseProgress = async (courseProgressId: string) => {
  const url = `/api/v1/batch_member_progresses/${courseProgressId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTopic = async (topicId: string) => {
  const url = `/api/v1/topics/${topicId}.json?`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
