import React from 'react';
import { Badge } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BellOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../redux/hooks';
import { selectNotificationsCount } from '../../redux/Notifications/NotificationsSlice';
import styles from './Notifications.module.scss';

interface Props {
  route: string;
}

const Notifications: React.FC<Props> = ({ route }) => {
  const notificationsCount = useAppSelector(selectNotificationsCount);
  const navigate = useNavigate();

  const onNavigate = () => {
    navigate(route);
  };

  return (
    <Badge
      className={styles['notification-badge']}
      size='small'
      count={notificationsCount}
      overflowCount={10}
    >
      <BellOutlined
        onClick={() => onNavigate()}
        style={{ fontSize: '18px', cursor: 'pointer', color: 'gray' }}
      />
    </Badge>
  );
};

export default Notifications;
