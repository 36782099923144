import React, { useEffect, useState, useRef } from 'react';
import styles from './RichInput.module.scss';

interface RichInputProps {
  initialValue?: string;
  value?: string;
  isOpen?: boolean;
  style?: React.CSSProperties;
  placeholder?: string;
  onInput?: (event: React.FormEvent<HTMLDivElement>) => void;
  onBlur?: (event: any) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  placeholderStyle?: React.CSSProperties;
  autoFocus?: boolean;
}

const RichInput: React.FC<RichInputProps> = ({
  value,
  isOpen,
  style,
  initialValue,
  placeholder,
  onInput,
  onBlur,
  onKeyDown,
  placeholderStyle = {},
  autoFocus = false,
}) => {
  const [propInitialValue, setPropInitialValue] = useState('');
  const textEditor = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (autoFocus && textEditor.current) {
      textEditor.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (initialValue) setPropInitialValue(initialValue);
  }, [initialValue]);

  return (
    <div className={styles['container']}>
      {!value && (
        <div className={styles['placeholder']} style={placeholderStyle}>
          {placeholder ? placeholder : ''}
        </div>
      )}
      <div className={styles['wrapper']} style={style}>
        <div
          ref={textEditor}
          contentEditable={isOpen}
          className={styles['rich-input']}
          onInput={onInput}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          dangerouslySetInnerHTML={{ __html: propInitialValue }}
        ></div>
      </div>
    </div>
  );
};

export default RichInput;
