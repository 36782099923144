import { axiosInstance } from '../api';

export const fetchAllActivities = async (
  batchId: string,
  page: number,
  perPage: number
) => {
  const url = `/api/v1/batches/${batchId}/batch_member_activities.json?page=${page}&perPage=${perPage}`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
