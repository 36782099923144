import { axiosInstance } from '../api';

export const fetchAllGoals = async (batchId: string) => {
  const url = `/api/v1/batches/${batchId}/goals.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchGoal = async (id: string) => {
  const url = `/api/v1/goals/${id}.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchMilestone = async (id: string) => {
  const url = `api/v1/milestones/${id}.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createGoal = async (
  batchId: string,
  title: string,
  description: string
) => {
  const url = `api/v1/goals.json`;

  const goal = {
    title: title,
    description: description,
    batch_id: batchId,
  };

  try {
    const response = await axiosInstance.post(url, goal);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createMilestone = async (
  goalId: string,
  trackableId: string,
  trackableType: string,
  title?: string,
  description?: string
) => {
  if (!goalId || !trackableId) return;

  const url = `api/v1/milestones.json`;

  const requestPayload = {
    milestone: {
      title: title,
      description,
      goal_id: goalId,
      trackable_id: trackableId,
      trackable_type: trackableType,
    },
  };

  try {
    const response = await axiosInstance.post(url, requestPayload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateGoal = async (
  attributes: any,
  goalId: string,
  batchId: string
) => {
  const url = `api/v1/goals/${goalId}.json`;

  const goal = {
    goal_id: goalId,
    batch_id: batchId,
    ...attributes,
  };

  try {
    const response = await axiosInstance.put(url, goal);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMilestone = async (attributes: any, id: string) => {
  const url = `api/v1/milestones/${id}.json`;

  const milestone = {
    ...attributes,
  };

  try {
    const response = await axiosInstance.put(url, milestone);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGoal = async (goalId: string) => {
  const url = `api/v1/goals/${goalId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMilestone = async (id: string) => {
  const url = `api/v1/milestones/${id}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createGoalAssignment = async (
  goalId: string,
  participantId: string,
  dueDate: string
) => {
  const url = `/api/v1/goal_assignments.json`;

  try {
    const response = await axiosInstance.post(url, {
      goal_assignment: {
        goal_id: goalId,
        batch_member_id: participantId,
        status: 'to_do',
        progress: 0,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGoalAssignment = async (
  assigneeId: string,
  goalId: string
) => {
  const url = `/api/v1/goal_assignments/${assigneeId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
