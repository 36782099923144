import { axiosInstance } from '../api';

export const fetchBatches = async (page: number) => {
  const url = `/api/v1/batches.json?page=${page}`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchBatch = async (paramsId: string) => {
  const url = `/api/v1/batches/${paramsId}.json`;

  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createBatch = async (attributes: any) => {
  const url = `/api/v1/batches.json`;
  const batch = {
    ...attributes,
  };

  try {
    const response = await axiosInstance.post(url, batch);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBatch = async (attributes: any, batchId: string) => {
  const url = `/api/v1/batches/${batchId}.json`;
  const batch = {
    ...attributes,
  };

  try {
    const response = await axiosInstance.put(url, batch);
    return response.data;
  } catch (error) {
    throw error;
  }
};
