import React from 'react';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  createChecklistRequest,
  selectLoadingChecklist,
} from '../../../redux/Tasks/TasksSlice';
import { PlusOutlined } from '@ant-design/icons';

interface CreateChecklistProps {
  projectId: string;
  taskId: string;
}

const CreateChecklist: React.FC<CreateChecklistProps> = ({
  projectId,
  taskId,
}) => {
  const dispatch = useAppDispatch();
  const creatingChecklist = useAppSelector(selectLoadingChecklist);

  const addChecklist = (e: any) => {
    dispatch(createChecklistRequest({ projectId: projectId, taskId: taskId }));
  };

  return (
    <div>
      <Button
        onClick={addChecklist}
        disabled={creatingChecklist}
        size='small'
        type='dashed'
      >
        <PlusOutlined /> New Checklist
      </Button>
    </div>
  );
};

export default CreateChecklist;
