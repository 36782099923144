import React, { useState, useRef } from 'react';
import { CommentType, ProgressReportType, TaskType } from '../../types';
import { Avatar, theme } from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  deleteCommentRequest,
  updateCommentRequest,
} from '../../redux/Tasks/TasksSlice';
import { updateProgressCommentRequest } from '../../redux/ProgressReports/ProgressReportsSlice';
import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import styles from './TaskComment.module.scss';
import Actions from '../Actions';
import RichInput from '../RichInput';
import { selectCurrentUser } from '../../redux/Users/UsersSlice';

const { useToken } = theme;

const commentStyle = {
  padding: 0,
  border: 'none',
};
const editCommentStyle = {
  border: '1px solid #e2e2e2',
};
interface Props {
  comment: CommentType;
  task?: TaskType;
  progressReport?: ProgressReportType;
}

const TaskComment: React.FC<Props> = ({ comment, progressReport, task }) => {
  const { token } = useToken();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const [editedCommentValue, setEditedCommentValue] = useState<string>(
    comment.content || ''
  );
  const [isOpen, setIsOpen] = useState(false);
  const commentBodyRef = useRef<HTMLDivElement | null>(null);

  const onOpenHandler = () => {
    setIsOpen(true);
  };

  const onEditComment = (e: any) => {
    const content = e.target.innerHTML;
    if (content) {
      setEditedCommentValue(content);
    } else {
      setEditedCommentValue('');
    }
  };

  const handleDelete = () => {
    if (comment.id) dispatch(deleteCommentRequest(comment.id));
  };

  const onBlurHandler = () => {
    if (editedCommentValue && editedCommentValue !== comment.content) {
      if (task?.id) {
        const commentId = comment.id;
        const value = editedCommentValue;
        dispatch(updateCommentRequest({ commentId, value }));
        setIsOpen(false);
      }
      if (progressReport?.uuid) {
        const commentId = comment.id;
        const value = editedCommentValue;
        dispatch(updateProgressCommentRequest({ commentId, value }));
        setIsOpen(false);
      }
    } else {
      setIsOpen(false);
    }
  };

  return (
    <div className={styles['comment-container']}>
      <div className={styles['user-avatar']}>
        <Avatar icon={<UserOutlined />} size={40} />
      </div>
      <div className={styles['comment-body']} ref={commentBodyRef}>
        <div className={styles['comment-heading']}>
          <div>
            <span className={styles['user-name']}>
              {comment.user_name || ''}
            </span>
            <span
              className={styles['message-date']}
              style={{ color: token.colorTextDescription }}
            >
              {moment(comment.created_at).format('DD MMM, hh:mm a')}
            </span>
          </div>
          {comment.user_id.toString() === currentUser?.id.toString() && (
            <div>
              <Actions
                options={[
                  {
                    label: 'Edit',
                    icon: <EditOutlined style={{ color: 'gray' }} />,
                    handleAction: onOpenHandler,
                  },
                  {
                    label: 'Delete',
                    icon: <DeleteOutlined style={{ color: '#bc4841' }} />,
                    handleAction: handleDelete,
                  },
                ]}
              />
            </div>
          )}
        </div>
        <RichInput
          isOpen={isOpen}
          initialValue={comment.content}
          value={editedCommentValue}
          onInput={onEditComment}
          onBlur={onBlurHandler}
          style={isOpen ? editCommentStyle : commentStyle}
        />
      </div>
    </div>
  );
};

export default TaskComment;
