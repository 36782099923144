import { axiosInstance } from '../api';
import { ChecklistItemType } from '../../types';

export const fetchAllTasks = async (projectId: string, sprintId?: string) => {
  let queryString = '';
  if (sprintId) queryString += `&sprint_id=${sprintId}`;
  const url = `/api/v1/projects/${projectId}/tasks.json?${queryString}`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTasksDetails = async (taskId: string) => {
  const url = `/api/v1/tasks/${taskId}.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createTask = async (
  title: string,
  projectId: string,
  assignee: string[],
  sprintId: number | null,
  status: string
) => {
  const url = `/api/v1/tasks.json`;

  try {
    const response = await axiosInstance.post(url, {
      task: {
        title,
        project_id: projectId,
        assignee,
        sprint_id: sprintId,
        status,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTask = async (
  key: string,
  taskId: string,
  value: string
) => {
  const url = `/api/v1/tasks/${taskId}.json`;
  const data: { task?: { [key: string]: string } } = {};

  if (key === 'title') {
    data.task = { title: value };
  } else if (key === 'description') {
    data.task = { description: value };
  } else if (key === 'status') {
    data.task = { status: value };
  } else if (key === 'due_date') {
    data.task = { due_date: value };
  } else if (key === 'priority') {
    data.task = { priority: value };
  }

  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTask = async (taskId: string) => {
  const url = `/api/v1/tasks/${taskId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createTaskAssignment = async (
  taskId: string,
  participantId: string
) => {
  const url = `/api/v1/task_assignments.json`;
  const task_assignment = {
    status: 'to_do',
    task_id: taskId,
    batch_member_id: participantId,
  };

  try {
    const response = await axiosInstance.post(url, task_assignment);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTaskAssignment = async (
  status: string,
  taskAssignmentId: string
) => {
  const url = `/api/v1/task_assignments/${taskAssignmentId}.json`;
  const task_assignment = {
    status,
  };

  try {
    const response = await axiosInstance.put(url, task_assignment);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTaskAssignment = async (assignmentId: string) => {
  const url = `/api/v1/task_assignments/${assignmentId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllComments = async (taskId: string) => {
  const url = `/api/v1/tasks/${taskId}/comments.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createComment = async (
  taskId: string,
  userId: string,
  newComment: string
) => {
  const url = `/api/v1/comments.json`;

  const comment = {
    content: newComment,
    commentable_id: taskId,
    commentable_type: 'Task',
    user_id: userId,
  };

  try {
    const response = await axiosInstance.post(url, comment);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateComment = async (commentId: string, value: string) => {
  const url = `/api/v1/comments/${commentId}.json`;
  const comment = {
    content: value,
  };

  try {
    const response = await axiosInstance.put(url, comment);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteComment = async (commentId: string) => {
  const url = `/api/v1/comments/${commentId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createChecklist = async (params: {
  projectId: string;
  taskId: string;
}) => {
  const url = `/api/v1/tasks/${params.taskId}/checklists.json`;

  const checklist = {
    task_id: params.taskId,
    title: 'Checklist',
  };

  try {
    const response = await axiosInstance.post(url, checklist);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteChecklist = async (params: {
  taskId: string;
  checklistId: string;
}) => {
  const url = `/api/v1/tasks/${params.taskId}/checklists/${params.checklistId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createChecklistItem = async (params: {
  projectId: string;
  taskId: string;
  checklistId: string;
  description: string;
}) => {
  const { taskId, checklistId, description } = params;
  const url = `/api/v1/tasks/${taskId}/checklists/${checklistId}/checklist_items.json`;

  const checklist_item = {
    checklist_id: checklistId,
    description,
  };

  try {
    const response = await axiosInstance.post(url, { checklist_item });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateChecklistItem = async (params: {
  projectId: string;
  taskId: string;
  checklistId: string;
  checklistItemId: string;
  checklistItem: Partial<ChecklistItemType>;
}) => {
  const { taskId, checklistId, checklistItemId, checklistItem } = params;
  const url = `/api/v1/tasks/${taskId}/checklists/${checklistId}/checklist_items/${checklistItemId}.json`;

  const checklist_item = {
    checklist_id: checklistId,
    ...checklistItem,
  };

  try {
    const response = await axiosInstance.put(url, { checklist_item });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteChecklistItem = async (params: {
  projectId: string;
  taskId: string;
  checklistId: string;
  checklistItemId: string;
}) => {
  const { taskId, checklistId, checklistItemId } = params;
  const url = `/api/v1/tasks/${taskId}/checklists/${checklistId}/checklist_items/${checklistItemId}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
