import React, { ReactNode, useState } from 'react';
import { Popover, Popconfirm } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import styles from './Actions.module.scss';

interface Option {
  label: string;
  icon: ReactNode;
  requireConfirmation?: boolean;
  handleAction: () => void;
}

interface Props {
  options: Option[];
}

const Actions: React.FC<Props> = ({ options }) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleActionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(!open);
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleConfirm = (
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    option: Option
  ) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    option.handleAction();
    handleOpenChange(false);
  };

  const handleCancel = (
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
      handleOpenChange(false);
    }
  };

  const content = () => {
    return (
      <div>
        {options.map((option, index) => (
          <div key={index}>
            {option.requireConfirmation ? (
              <Popconfirm
                title='Are you sure you want to delete?'
                onConfirm={(e) => handleConfirm(e, option)}
                okText='Yes'
                cancelText='No'
                onCancel={handleCancel}
              >
                <a onClick={handleDeleteClick} key={option.label}>
                  <div className={styles['action']}>
                    {option.icon}
                    <span style={{ marginLeft: 8, color: '#bc4841' }}>
                      {option.label}
                    </span>
                  </div>
                </a>
              </Popconfirm>
            ) : (
              <div key={option.label}>
                <a
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    e.preventDefault();
                    option.handleAction();
                    handleOpenChange(false);
                  }}
                >
                  <div className={styles['action']}>
                    {option.icon}
                    <span style={{ marginLeft: 8 }}>{option.label}</span>
                  </div>
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Popover
      placement='bottomRight'
      content={content}
      trigger='click'
      open={open}
      onOpenChange={handleOpenChange}
      overlayInnerStyle={{
        padding: '8px 0px',
        minWidth: 120,
      }}
    >
      <span className={styles['more']} onClick={handleActionClick}>
        <EllipsisOutlined style={{ fontSize: 20, fontWeight: '600' }} />
      </span>
    </Popover>
  );
};

export default Actions;
