import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  createBatchMember,
  fetchBatchMember,
  fetchBatchMembers,
} from './BatchMembersAPI';
import { BatchMemberType } from '../../types';

export interface BatchMembersState {
  batchMembers: BatchMemberType[];
  batchMembersLoading: boolean;
  batchMember: BatchMemberType | null;
  batchMemberLoading: boolean;
  creatingBatchMember: boolean;
}

const initialState: BatchMembersState = {
  batchMember: null,
  batchMemberLoading: true,
  batchMembers: [],
  batchMembersLoading: true,
  creatingBatchMember: false,
};

export const fetchBatchMemberRequest = createAsyncThunk(
  'batchMembers/fetchBatchMemberRequest',
  async (memberId: string) => {
    const response = await fetchBatchMember(memberId);
    return response;
  }
);

export const fetchBatchMembersRequest = createAsyncThunk(
  'batchMembers/fetchBatchMembersRequest',
  async (batchId: string) => {
    const response = await fetchBatchMembers(batchId);
    return response;
  }
);

export const createBatchMemberRequest = createAsyncThunk(
  'batchMembers/createBatchMemberRequest',
  async (params: {
    batchId: string;
    first_name: string;
    last_name: string;
    email: string;
  }) => {
    const { batchId, first_name, last_name, email } = params;
    const response = await createBatchMember(
      batchId,
      first_name,
      last_name,
      email
    );
    return response;
  }
);

export const batchMembersSlice = createSlice({
  name: 'bathMembers',
  initialState,
  reducers: {
    resetBatchMemberState: () => initialState,
    updateBatchMember: (state) => {
      state.batchMember = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBatchMemberRequest.pending, (state) => {
        state.batchMemberLoading = true;
      })
      .addCase(fetchBatchMemberRequest.fulfilled, (state, action) => {
        state.batchMemberLoading = false;
        state.batchMember = action.payload;
      })
      .addCase(fetchBatchMemberRequest.rejected, (state) => {
        state.batchMemberLoading = false;
      })
      .addCase(fetchBatchMembersRequest.pending, (state) => {
        state.batchMembersLoading = true;
      })
      .addCase(fetchBatchMembersRequest.fulfilled, (state, action) => {
        state.batchMembersLoading = false;
        state.batchMembers = action.payload;
      })
      .addCase(fetchBatchMembersRequest.rejected, (state) => {
        state.batchMembersLoading = false;
      })
      .addCase(createBatchMemberRequest.pending, (state) => {
        state.creatingBatchMember = true;
      })
      .addCase(createBatchMemberRequest.fulfilled, (state, action) => {
        state.creatingBatchMember = false;
        state.batchMembers = [...state.batchMembers, action.payload];
      })
      .addCase(createBatchMemberRequest.rejected, (state) => {
        state.creatingBatchMember = false;
      });
  },
});

export const { updateBatchMember, resetBatchMemberState } =
  batchMembersSlice.actions;

export const selectBatchMember = (state: RootState) =>
  state.batchMembers.batchMember;
export const selectBatchMemberLoading = (state: RootState) =>
  state.batchMembers.batchMemberLoading;
export const selectBatchMembers = (state: RootState) =>
  state.batchMembers.batchMembers;
export const selectBatchMembersLoading = (state: RootState) =>
  state.batchMembers.batchMembersLoading;

export default batchMembersSlice.reducer;
