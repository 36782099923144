import React, { useEffect, useState, useRef } from 'react';
import styles from './InLineRichInput.module.scss';

interface RichInputProps {
  initialValue: string;
  value?: string;
  isOpen?: boolean;
  style?: React.CSSProperties;
  placeholder?: string;
  onInput?: (value: any) => void;
  onBlur?: () => void;
  onKeyDown?: (e: any) => void;
}

const InLineRichInput: React.FC<RichInputProps> = ({
  value,
  isOpen,
  style,
  initialValue,
  placeholder,
  onInput,
  onBlur,
  onKeyDown,
}) => {
  const [propInitialValue, setPropInitialValue] = useState('');
  const textEditor = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (textEditor.current) {
  //     textEditor.current.focus();
  //   }
  // }, [isOpen]);

  useEffect(() => {
    if (initialValue) setPropInitialValue(initialValue);
  }, [initialValue]);

  return (
    <span className={styles['container']}>
      {!value && (
        <span className={styles['placeholder']}>
          {placeholder ? placeholder : ''}
        </span>
      )}
      <span className={styles['wrapper']} style={style}>
        <span
          ref={textEditor}
          contentEditable={isOpen}
          className={styles['rich-input']}
          onInput={onInput}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          dangerouslySetInnerHTML={{ __html: propInitialValue }}
        ></span>
      </span>
    </span>
  );
};

export default InLineRichInput;
