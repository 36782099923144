import React from 'react';
import styles from './Avatar.module.scss';

const Avatar: React.FC<{
  content: string | React.ReactNode;
  style?: React.CSSProperties;
}> = ({ content, style }) => {
  return (
    <span className={styles.avatar} style={style}>
      <span className={styles.content}>{content}</span>
    </span>
  );
};

export default Avatar;
