import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ChannelType, MessageType } from '../../types';
import {
  fetchMessages,
  sendMessage,
  deleteMessage,
  updateMessage,
  fetchThreadMessages,
  updateLastMessageReadTime,
  sendEmailMessage,
} from './ChatAPI';

interface ChatState {
  messages: any;
  threadMessages: any;
  loadingMessages: boolean;
  threadMessagesLoading: boolean;
  page: number;
  loadingMore: boolean;
  currentChannelPage: number | null;
}

const initialState: ChatState = {
  messages: {},
  loadingMessages: false,
  threadMessages: {},
  threadMessagesLoading: false,
  page: 1,
  loadingMore: false,
  currentChannelPage: null,
};

export const fetchMessagesRequest = createAsyncThunk(
  'chat/fetchMessagesRequest',
  async (
    params: {
      spaceId: string | null;
      channelId: string | null;
      page: number;
      parentId?: string;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const { spaceId, channelId, page, parentId } = params;
    let lastSyncAt = null;
    if (channelId) {
      const channelMessages = state.chat.messages[channelId];
      lastSyncAt = channelMessages ? channelMessages['lastSyncAt'] : null;
    }
    if (spaceId && channelId) {
      const response = await fetchMessages(
        spaceId,
        channelId,
        page,
        lastSyncAt,
        parentId
      );
      return response;
    }
  }
);

export const fetchThreadMessagesRequest = createAsyncThunk(
  'chat/fetchThreadMessagesRequest',
  async (params: {
    spaceId: string;
    channelId: string;
    page: number;
    parentId: string;
  }) => {
    const { spaceId, channelId, page, parentId } = params;
    const response = await fetchThreadMessages(
      spaceId,
      channelId,
      page,
      parentId
    );
    return response;
  }
);

export const sendMessageRequest = createAsyncThunk(
  'chat/sendMessageRequest',
  async (params: {
    spaceId: string;
    channelId: string;
    userId: string;
    body: string;
    parentId?: string;
  }) => {
    const { spaceId, userId, body, parentId, channelId } = params;
    const response = await sendMessage(
      spaceId,
      channelId,
      userId,
      body,
      parentId
    );
    return response;
  }
);

export const deleteMessageRequest = createAsyncThunk(
  'chat/deleteMessageRequest',
  async (params: { messageId: string; channelId: string }) => {
    const { messageId } = params;
    return await deleteMessage(messageId);
  }
);

export const updateMessageRequest = createAsyncThunk(
  'chat/updateMessageRequest',
  async (params: {
    messageId: string;
    messageBody: string;
    channelId: string;
  }) => {
    const { messageId, messageBody } = params;
    const updatedMessage = await updateMessage(messageId, messageBody);
    return updatedMessage;
  }
);

export const updateLastMessageReadTimeRequest = createAsyncThunk(
  'chat/updateLastMessageReadTimeRequest',
  async (params: { channelMembershipId: string; time: string }) => {
    const { channelMembershipId, time } = params;
    return await updateLastMessageReadTime(channelMembershipId, time);
  }
);

export const sendEmailMessageRequest = createAsyncThunk(
  'chat/sendEmailMessageRequest',
  async (params: { messageId: number }) => {
    const { messageId } = params;
    return await sendEmailMessage(messageId);
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    loadMoreMessages: (
      state,
      action: PayloadAction<{ currentChannel: ChannelType }>
    ) => {
      state.loadingMore = true;

      const selectedChannelId = action.payload.currentChannel.id;
      const selectedChannel = state.messages[selectedChannelId];

      if (selectedChannel) {
        state.currentChannelPage = selectedChannel.page + 1;
        selectedChannel.page = selectedChannel.page + 1;
        state.messages[selectedChannelId] = selectedChannel;
      }
    },
    updateCurrentChannelPage: (state) => {
      state.currentChannelPage = null;
    },
    resetChatState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessagesRequest.pending, (state) => {
        state.loadingMessages = true;
      })
      .addCase(fetchMessagesRequest.fulfilled, (state, action) => {
        state.loadingMessages = false;
        const channelId = action.meta.arg.channelId;
        if (channelId) {
          state.messages[channelId] = state.messages[channelId] || {};
          const existingMessages = state.messages[channelId]['messages'] || [];
          let messageIds = state.messages[channelId]['messageIds'] || [];
          const newMessages = action.payload.messages.filter(
            (message: MessageType) => !messageIds.includes(message.id)
          );

          newMessages.forEach((message: MessageType) => {
            messageIds.push(message.id);
          });
          state.messages[channelId]['messageIds'] = Array.from(messageIds);
          state.messages[channelId]['messages'] = [
            ...existingMessages,
            ...newMessages,
          ];
          state.messages[channelId]['page'] = action.payload.current_page;
          state.messages[channelId]['lastSyncAt'] = Math.round(
            new Date().getTime() / 1000
          );
        }
        state.loadingMore = false;
      })
      .addCase(fetchMessagesRequest.rejected, (state) => {
        state.loadingMessages = false;
      })
      .addCase(fetchThreadMessagesRequest.pending, (state) => {
        state.threadMessagesLoading = true;
      })
      .addCase(fetchThreadMessagesRequest.fulfilled, (state, action) => {
        state.threadMessagesLoading = false;
        const parentId = action.meta.arg.parentId;
        if (parentId) {
          state.threadMessages[parentId] = state.threadMessages[parentId] || {};
          state.threadMessages[parentId]['messages'] = action.payload.messages;
          state.threadMessages[parentId]['page'] = action.payload.current_page;
        }
        state.loadingMore = false;
      })
      .addCase(fetchThreadMessagesRequest.rejected, (state) => {
        state.threadMessagesLoading = false;
      })
      .addCase(sendMessageRequest.pending, (state) => {
        state.loadingMessages = false;
      })
      .addCase(sendMessageRequest.fulfilled, (state, action) => {
        state.loadingMessages = false;
        const parentId = action.meta.arg.parentId;
        const channelId = action.meta.arg.channelId;
        if (parentId) {
          state.threadMessages[parentId]['messages'] = [
            ...state.threadMessages[parentId].messages,
            action.payload,
          ];
          const channelMessages = state.messages[channelId]['messages'];
          const UpdatedMessages = [...channelMessages];
          const messageIndex = channelMessages.findIndex(
            (message: MessageType) => message.id.toString() === parentId
          );
          if (messageIndex >= 0) {
            const currentMessage = channelMessages[messageIndex];
            if (currentMessage.replies_count) {
              currentMessage.replies_count = currentMessage.replies_count + 1;
            } else {
              currentMessage.replies_count = 1;
            }
            UpdatedMessages.splice(messageIndex, 1, currentMessage);
          }
          state.messages[channelId]['messages'] = UpdatedMessages;
        } else
          state.messages[channelId]['messages'] = [
            ...state.messages[channelId].messages,
            action.payload,
          ];
        state.messages[channelId]['messageIds'] = [
          ...state.messages[channelId]['messageIds'],
          action.payload.id,
        ];
      })
      .addCase(sendMessageRequest.rejected, (state) => {
        state.loadingMessages = false;
      })
      .addCase(updateMessageRequest.pending, (state) => {
        state.loadingMessages = false;
      })
      .addCase(updateMessageRequest.fulfilled, (state, action) => {
        state.loadingMessages = false;
        const channelId = action.meta.arg.channelId;
        const channelMessages = state.messages[channelId]['messages'];
        const UpdatedMessages = [...channelMessages];
        const index = channelMessages.findIndex(
          (message: MessageType) => message.id === action.payload.id
        );
        UpdatedMessages.splice(index, 1, action.payload);
        state.messages[channelId]['messages'] = UpdatedMessages;
      })
      .addCase(updateMessageRequest.rejected, (state) => {
        state.loadingMessages = false;
      })
      .addCase(deleteMessageRequest.pending, (state) => {
        state.loadingMessages = false;
      })
      .addCase(deleteMessageRequest.fulfilled, (state, action) => {
        state.loadingMessages = false;
        const channelId = action.meta.arg.channelId;
        const channelMessages = state.messages[channelId]['messages'];
        const messages = channelMessages.filter(
          (message: MessageType) =>
            message.id.toString() !== action.meta.arg.messageId
        );
        state.messages[channelId]['messages'] = messages;
      })
      .addCase(deleteMessageRequest.rejected, (state) => {
        state.loadingMessages = false;
      })
      .addCase(sendEmailMessageRequest.pending, (state) => {
        state.loadingMessages = false;
      })
      .addCase(sendEmailMessageRequest.fulfilled, (state, action) => {
        state.loadingMessages = false;
      })
      .addCase(sendEmailMessageRequest.rejected, (state) => {
        state.loadingMessages = false;
      });
  },
});

export const { updateCurrentChannelPage, loadMoreMessages, resetChatState } =
  chatSlice.actions;

export const selectMessages = (state: RootState) => state.chat.messages;
export const selectLoadingMessages = (state: RootState) =>
  state.chat.loadingMessages;
export const selectThreadMessages = (state: RootState) =>
  state.chat.threadMessages;
export const selectThreadLoadingMessages = (state: RootState) =>
  state.chat.threadMessagesLoading;
export const selectPage = (state: RootState) => state.chat.page;
export const selectLoadingMore = (state: RootState) => state.chat.loadingMore;
export const selectCurrentChannelPage = (state: RootState) =>
  state.chat.currentChannelPage;

export default chatSlice.reducer;
