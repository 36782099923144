import { axiosInstance } from '../api';
import { sanitizeRichText } from '../../utils/string';

export const fetchMessages = async (
  spaceId: string,
  channelId: string,
  page: number,
  lastSyncAt?: string,
  parentId?: string
) => {
  try {
    let queryParamsString = '';
    queryParamsString += `space_id=${spaceId}`;
    queryParamsString += `&channel_id=${channelId}`;
    queryParamsString += `&page=${page}`;
    if (parentId) queryParamsString += `&parent_id=${parentId}`;
    if (lastSyncAt) {
      queryParamsString += `&last_sync_at=${lastSyncAt}`;
    }
    const url = `/chat/api/v1/messages.json?${queryParamsString}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchThreadMessages = async (
  spaceId: string,
  channelId: string,
  page: number,
  parentId: string
) => {
  try {
    let queryParamsString = '';
    queryParamsString += `space_id=${spaceId}`;
    queryParamsString += `&channel_id=${channelId}`;
    queryParamsString += `&page=${page}`;
    if (parentId) queryParamsString += `&parent_id=${parentId}`;
    const url = `/chat/api/v1/messages.json?${queryParamsString}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendMessage = async (
  spaceId: string,
  channelId: string,
  userId: string,
  messageBody: string,
  parentId?: string
) => {
  const message = {
    body: sanitizeRichText(messageBody),
    author_id: userId,
    author_type: 'User',
    parent_id: parentId,
    space_id: spaceId,
    channel_id: channelId,
  };

  try {
    const response = await axiosInstance.post(`/chat/api/v1/messages.json`, {
      message,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMessage = async (messageId: string) => {
  try {
    const response = await axiosInstance.delete(
      `/chat/api/v1/messages/${messageId}.json`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMessage = async (messageId: string, messageBody: string) => {
  try {
    const response = await axiosInstance.put(
      `/chat/api/v1/messages/${messageId}.json`,
      {
        message: {
          body: sanitizeRichText(messageBody),
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateLastMessageReadTime = async (
  channelMembershipId: string,
  time: string
) => {
  try {
    const response = await axiosInstance.put(
      `/chat/api/v1/channel_memberships/${channelMembershipId}.json`,
      {
        channel_membership: {
          last_message_read_at: time,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendEmailMessage = async (messageId: number) => {
  const url = `api/v1/messages/${messageId}/send_mail`;

  try {
    const response = await axiosInstance.post(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
