import React, { useState, useEffect, useRef } from 'react';
import { Typography, theme } from 'antd';
import { TaskType, CommentType, ProgressReportType } from '../../types';
import RichTextEditor from '../RichTextEditor';
import styles from './TaskComments.module.scss';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { createCommentRequest } from '../../redux/Tasks/TasksSlice';
import { createProgressCommentRequest } from '../../redux/ProgressReports/ProgressReportsSlice';
import TaskComment from '../TaskComment';
import { selectCurrentUser } from '../../redux/Users/UsersSlice';

const { Title } = Typography;
const { useToken } = theme;

interface TaskCommentsProps {
  comments?: CommentType[];
  task?: TaskType;
  progressReport?: ProgressReportType;
}

const TaskComments: React.FC<TaskCommentsProps> = ({
  task,
  comments,
  progressReport,
}) => {
  const { token } = useToken();
  const dispatch = useAppDispatch();
  const [newComment, setNewComment] = useState<string>('');
  const [module, setModule] = useState(false);
  const currentUser = useAppSelector(selectCurrentUser);
  const commentEl = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (commentEl.current) {
      const container = commentEl.current;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [comments]);

  const handleAddComment = () => {
    if (newComment && currentUser?.id) {
      if (task?.id) {
        const taskId = task.id;
        const userId = currentUser?.id;
        dispatch(createCommentRequest({ taskId, userId, newComment }));
      }
      if (progressReport?.uuid) {
        const progressId = progressReport.uuid;
        const userId = currentUser?.id;
        dispatch(
          createProgressCommentRequest({ progressId, userId, newComment })
        );
      }
    }
    setNewComment('');
  };

  const handleChangeInput = (value: string) => {
    setNewComment(value);
  };

  const onModule = (value: boolean) => {
    setModule(value);
  };

  return (
    <React.Fragment>
      <div
        className={styles['comments-list']}
        ref={commentEl as React.LegacyRef<HTMLDivElement>}
      >
        <Title
          level={5}
          style={{ color: token.colorText, marginBottom: 16, marginTop: 0 }}
        >
          Comments
        </Title>
        {comments?.map((comment) => (
          <TaskComment
            key={comment.id}
            comment={comment}
            task={task}
            progressReport={progressReport}
          />
        ))}
      </div>
      <div style={{ margin: '16px 0' }}>
        <RichTextEditor
          module={module}
          onModule={onModule}
          value={newComment}
          placeholder='Write comment ...'
          onInput={handleChangeInput}
          onSend={handleAddComment}
        />
      </div>
    </React.Fragment>
  );
};

export default TaskComments;
