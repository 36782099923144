import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { BASE_URL } from '../utils/constants';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

const setupAxiosInterceptors = (store: any) => {
  axiosInstance.interceptors.request.use(
    (config: any | AxiosRequestConfig) => {
      const state = store.getState();
      const currentUser = state.users.currentUser;
      config.headers['access-token'] = currentUser?.accessToken;
      config.headers['client'] = currentUser?.client;
      config.headers['uid'] = currentUser?.uid;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export { axiosInstance };
export default setupAxiosInterceptors;
