import { axiosInstance } from '../api';

export const fetchAllSpaces = async () => {
  const url = `api/v1/spaces.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSpaceBatches = async (spaceId: string) => {
  const url = `/api/v1/spaces/${spaceId}/batches.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSpaceMembers = async (spaceId: string) => {
  const url = `/api/v1/spaces/${spaceId}/space_members.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSpaceMember = async (attributes: any) => {
  const url = `/api/v1/space_members.json`;

  const space_member = {
    ...attributes,
  };

  try {
    const response = await axiosInstance.post(url, space_member);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchBatchesParticipants = async (spaceId: string) => {
  const url = `/api/v1/spaces/${spaceId}/batch_members.json`;

  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSpace = async (id: string) => {
  const url = `/api/v1/spaces/${id}.json`;

  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createSpace = async (name: string, organizationId: string) => {
  const url = `api/v1/spaces.json`;

  const space = {
    name,
    organization_id: organizationId,
  };

  try {
    const response = await axiosInstance.post(url, space);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSpace = async (attributes: any, spaceId: string) => {
  const url = `api/v1/spaces/${spaceId}.json`;

  const space = {
    ...attributes,
  };

  try {
    const response = await axiosInstance.put(url, space);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSpace = async (id: string) => {
  const url = `api/v1/spaces/${id}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSpaceMember = async (id: string) => {
  const url = `/api/v1/space_members/${id}.json`;

  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
