import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchCourseModules,
  fetchParticipantProgress,
  updateCourseProgress,
  deleteCourseProgress,
  fetchTopic,
  fetchAllCourses,
} from './CoursesAPI';
import {
  CourseModuleType,
  CourseProgressType,
  CourseType,
  TopicType,
} from '../../types';
import { RootState } from '../store';

interface CoursesState {
  allCourses: CourseType[];
  allCoursesLoading: boolean;
  courseProgress: CourseProgressType[];
  allCourseModules: CourseModuleType[];
  courseLoading: boolean;
  topic: TopicType | null;
  topicLoading: boolean;
}

const initialState: CoursesState = {
  allCourses: [],
  allCoursesLoading: true,
  courseProgress: [],
  allCourseModules: [],
  courseLoading: true,
  topic: null,
  topicLoading: false,
};

export const fetchParticipantProgressRequest = createAsyncThunk(
  'courses/fetchParticipantProgressRequest',
  async (participantId: string) => {
    const response = await fetchParticipantProgress(participantId);
    return response;
  }
);

export const fetchAllCoursesRequest = createAsyncThunk(
  'courses/fetchAllCoursesRequest',
  async () => {
    const response = await fetchAllCourses();
    return response;
  }
);

export const fetchAllCourseModulesRequest = createAsyncThunk(
  'courses/fetchAllCourseModulesRequest',
  async (courseId: string) => {
    const response = await fetchCourseModules(courseId);
    return response;
  }
);

export const updateCourseProgressRequest = createAsyncThunk(
  'courses/updateCourseProgressRequest',
  async (params: { participantId: string; topicId: string }) => {
    const { participantId, topicId } = params;
    const response = await updateCourseProgress(participantId, topicId);
    return response;
  }
);

export const deleteCourseProgressRequest = createAsyncThunk(
  'courses/deleteCourseProgressRequest',
  async (courseProgressId: string) => {
    const response = await deleteCourseProgress(courseProgressId);
    return response;
  }
);

export const fetchTopicsRequest = createAsyncThunk(
  'courses/fetchTopicsRequest',
  async (topicId: string) => {
    const response = await fetchTopic(topicId);
    return response;
  }
);

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    clearCourseProgress: (state) => {
      state.courseProgress = [];
    },
    resetCourseState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchParticipantProgressRequest.pending, (state) => {
        state.courseLoading = false;
      })
      .addCase(fetchParticipantProgressRequest.fulfilled, (state, action) => {
        state.courseLoading = false;
        state.courseProgress = action.payload;
      })
      .addCase(fetchParticipantProgressRequest.rejected, (state) => {
        state.courseLoading = false;
      })
      .addCase(fetchAllCoursesRequest.pending, (state) => {
        state.allCoursesLoading = true;
      })
      .addCase(fetchAllCoursesRequest.fulfilled, (state, action) => {
        state.allCoursesLoading = false;
        state.allCourses = action.payload;
      })
      .addCase(fetchAllCoursesRequest.rejected, (state) => {
        state.allCoursesLoading = false;
      })
      .addCase(fetchAllCourseModulesRequest.pending, (state) => {
        state.courseLoading = true;
      })
      .addCase(fetchAllCourseModulesRequest.fulfilled, (state, action) => {
        state.courseLoading = false;
        state.allCourseModules = action.payload.course_modules;
      })
      .addCase(fetchAllCourseModulesRequest.rejected, (state) => {
        state.courseLoading = false;
      })
      .addCase(updateCourseProgressRequest.pending, (state) => {
        state.courseLoading = false;
      })
      .addCase(updateCourseProgressRequest.fulfilled, (state, action) => {
        state.courseLoading = false;
        state.courseProgress = [...state.courseProgress, action.payload];
      })
      .addCase(updateCourseProgressRequest.rejected, (state) => {
        state.courseLoading = false;
      })
      .addCase(deleteCourseProgressRequest.pending, (state) => {
        state.courseLoading = false;
      })
      .addCase(deleteCourseProgressRequest.fulfilled, (state, action) => {
        state.courseLoading = false;
        const updatedProgress = state.courseProgress.filter(
          (progress) => progress.id !== action.meta.arg
        );
        state.courseProgress = updatedProgress;
      })
      .addCase(deleteCourseProgressRequest.rejected, (state) => {
        state.courseLoading = false;
      })
      .addCase(fetchTopicsRequest.pending, (state) => {
        state.topicLoading = true;
      })
      .addCase(fetchTopicsRequest.fulfilled, (state, action) => {
        state.topicLoading = false;
        state.topic = action.payload;
      })
      .addCase(fetchTopicsRequest.rejected, (state) => {
        state.topicLoading = false;
      });
  },
});

export const { clearCourseProgress, resetCourseState } = coursesSlice.actions;

export const selectCourseProgress = (state: RootState) =>
  state.courses.courseProgress;
export const selectAllCourseModules = (state: RootState) =>
  state.courses.allCourseModules;
export const selectTopic = (state: RootState) => state.courses.topic;
export const selectCourseLoading = (state: RootState) =>
  state.courses.courseLoading;
export const selectTopicLoading = (state: RootState) =>
  state.courses.topicLoading;
export const selectAllCourses = (state: RootState) => state.courses.allCourses;
export const selectAllCoursesLoading = (state: RootState) =>
  state.courses.allCoursesLoading;

export default coursesSlice.reducer;
