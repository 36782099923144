import React, { useState, useEffect } from 'react';
import {
  TaskType,
  BatchMemberType,
  TaskAssignmentsType,
  ProjectType,
  ProjectMemberType,
} from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectBatchMembers } from '../../redux/BatchMembers/BatchMembersSlice';
import {
  createTaskAssignmentRequest,
  deleteTaskAssignmentRequest,
} from '../../redux/Tasks/TasksSlice';
import { Popover } from 'antd';
import TaskAssignment from '../TaskAssignment';
import styles from './TaskAssignTo.module.scss';
import { createProjectMemberRequest } from '../../redux/Projects/ProjectsSlice';
import ProjectMembers from './components/ProjectMembers';
import AssigneeList from './components/AssigneeList';

interface TaskAssignToProps {
  task?: TaskType;
  project?: ProjectType;
  addTaskAssignTo?: (assignTo: string[]) => void;
}

const TaskAssignTo: React.FC<TaskAssignToProps> = ({
  task,
  project,
  addTaskAssignTo,
}) => {
  const dispatch = useAppDispatch();
  const batchMembers = useAppSelector(selectBatchMembers);
  const [open, setOpen] = useState(false);
  const [membersId, setMembersId] = useState<string[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    []
  );
  const [taskAssignments, setTaskAssignments] = useState<TaskAssignmentsType[]>(
    []
  );
  const [projectMembers, setProjectMembers] = useState<ProjectMemberType[]>([]);

  useEffect(() => {
    if (task) setTaskAssignments(task.task_assignments);
  }, [task]);

  useEffect(() => {
    if (project) setProjectMembers(project.project_members);
  }, [project]);

  useEffect(() => {
    addTaskAssignTo?.(selectedParticipants);
  }, [selectedParticipants]);

  const addTaskAssignee = (id: string) => {
    if (task?.id) {
      setOpen(false);
      const assignment = task.task_assignments.find(
        (assignment) => assignment.batch_member_id === id
      );
      if (assignment) {
        return;
      }
      const taskId = task.id;
      const participantId = id;
      dispatch(
        createTaskAssignmentRequest({
          taskId,
          participantId,
          projectId: task.project_id,
        })
      );
    }

    if (project?.id) {
      const assignment = project.project_members.find(
        (assignment) => assignment.participant_id === id
      );
      if (assignment) {
        return;
      }
      setOpen(false);
      const projectId = project.id;
      const participantId = id;
      dispatch(createProjectMemberRequest({ projectId, participantId }));
    }
  };

  const deleteTaskAssignment = (batchMemberId: string) => {
    if (!task?.id) return;
    setOpen(false);
    const assignment = task.task_assignments.find(
      (assignment) => assignment.batch_member_id === batchMemberId
    );
    if (assignment) {
      dispatch(
        deleteTaskAssignmentRequest({
          id: assignment.id,
          taskId: task.id,
          projectId: task.project_id,
        })
      );
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    if (task) {
      const assignees = taskAssignments?.map(
        (assignment) => assignment.batch_member_id
      );
      if (assignees) {
        setMembersId(assignees);
        setSelectedParticipants(assignees);
      }
    }
    if (project) {
      const assignees = projectMembers.map((member) => member.participant_id);
      if (assignees) {
        setMembersId(assignees);
        setSelectedParticipants(assignees);
      }
    }
  };

  const handleSelectMember = (memberId: string) => {
    setSelectedParticipants((prevSelected: string[]) => {
      if (prevSelected.includes(memberId)) {
        return prevSelected.filter((p) => p !== memberId);
      }
      return [...prevSelected, memberId];
    });
  };

  const popoverContent = () => (
    <div className={styles['task-assign-to']}>
      {batchMembers.map((member: BatchMemberType, index) => {
        const taskStatus = task?.status;
        return (
          <TaskAssignment
            id={index}
            key={`task-assignment-${index}`}
            member={member}
            selectedParticipants={selectedParticipants}
            membersId={membersId}
            status={taskStatus}
            handleSelectMember={handleSelectMember}
            addTaskAssignee={addTaskAssignee}
            deleteTaskAssignment={deleteTaskAssignment}
          />
        );
      })}
    </div>
  );

  return (
    <Popover
      placement='bottom'
      content={popoverContent}
      trigger='click'
      open={open}
      onOpenChange={handleOpenChange}
    >
      {task && <AssigneeList taskAssignments={task.task_assignments} />}
      {project && <ProjectMembers projectMembers={projectMembers} />}
    </Popover>
  );
};

export default TaskAssignTo;
