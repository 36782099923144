import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { useAppSelector } from './redux/hooks';
import { selectCurrentUser } from './redux/Users/UsersSlice';
import Tasks from './routes/Tasks/Loadable';
import BatchDashboard from './routes/BatchDashboard/Loadable';
import SpaceChat from './routes/SpaceChat/Loadable';
import Course from './routes/Course/Loadable';
import ProgressReports from './routes/ProgressReports/Loadable';
import Dashboard from './routes/Dashboard/Loadable';
import Home from './routes/Home/Loadable';
import Login from './routes/Login/Loadable';
import BatchMembers from './routes/BatchMembers/Loadable';
import SiteLayout from './components/Layout/Layout';
import NoLayout from './components/Layout/NoLayout';
import TimeTracker from './routes/TimeTracker/Loadable';
import Topic from './routes/Topic/Loadable';
import TimeLogSummary from './routes/TimeLogSummary/Loadable';
import ProgressReportDetails from './components/ProgressReportDetails/Loadable';
import EditCourse from './routes/EditCourse/Loadable';
import ParticipantTimeEntries from './routes/ParticipantTimeEntries/Loadable';
import LatestTimeEntries from './routes/LatestTimeEntries/Loadable';
import Projects from './routes/Projects/Loadable';
import Project from './routes/Project/Loadable';
import Notifications from './routes/Notifications/Loadable';
import TaskDetailsModal from './components/TaskDetailsModal';
import Goals from './routes/Goals/Loadable';
import Goal from './routes/Goal/Loadable';
import Courses from './routes/Courses/Loadable';
import Spaces from './routes/Spaces/Loadable';
import Batches from './routes/Batches/Loadable';
import SpaceDashboard from './routes/SpaceDashboard/Loadable';
import SpaceProtectedRoutes from './routes/SpaceProtectedRoutes/Loadable';
import SpaceBatches from './routes/SpaceBatches/Loadable';
import SpaceMembers from './routes/SpaceMembers/Loadable';
import BatchProtectedRoutes from './routes/BatchProtectedRoutes/Loadable';
import BatchChat from './routes/BatchChat/Loadable';

interface ProtectedRouteProps {
  children?: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();
  const currentUser = useAppSelector(selectCurrentUser);

  if (isEmpty(currentUser) && location.pathname !== '/login') {
    return <Navigate to='/login' replace />;
  }

  return children ? children : <Outlet />;
};

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path='/*' element={<NoLayout />}>
      <Route path='login' element={<Login />} />
      <Route index element={<Home />} />
    </Route>

    <Route path='/*' element={<SiteLayout />}>
      <Route element={<ProtectedRoute />}>
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='courses' element={<Courses />} />
        <Route path='spaces' element={<Spaces />} />
        <Route path='batches' element={<Batches />} />
        <Route path='notifications' element={<Notifications />} />
        <Route path='task/:taskId' element={<TaskDetailsModal />} />
      </Route>
    </Route>

    <Route element={<ProtectedRoute />}>
      <Route path='space/:spaceId' element={<SpaceProtectedRoutes />}>
        <Route index element={<SpaceDashboard />} />
        <Route path='chat' element={<SpaceChat />} />
        <Route path='batches' element={<SpaceBatches />} />
        <Route path='members' element={<SpaceMembers />} />
        <Route path='notifications' element={<Notifications />} />
      </Route>
    </Route>

    <Route element={<ProtectedRoute />}>
      <Route path='batch/:id' element={<BatchProtectedRoutes />}>
        <Route index element={<BatchDashboard />} />
        <Route path='chat' element={<BatchChat />} />
        <Route path='notifications' element={<Notifications />} />
        <Route path='course-modules' element={<Course />} />
        <Route path='goals' element={<Goals />} />
        <Route path='goals/:goalId' element={<Goal />} />
        <Route path='progress-reports' element={<ProgressReports />} />
        <Route
          path='progress-reports/:progressId'
          element={<ProgressReportDetails />}
        />
        <Route path='members' element={<BatchMembers />} />
        <Route
          path='members/:participantId/daily_time_summary'
          element={<TimeLogSummary />}
        />
        <Route
          path='members/:participantId/time_entries'
          element={<ParticipantTimeEntries />}
        />
        <Route path='time-entries' element={<LatestTimeEntries />} />
        <Route path='time-tracker' element={<TimeTracker />} />
        <Route path='projects' element={<Projects />}>
          <Route path=':projectId' element={<Tasks />} />
          <Route path=':projectId/:taskId' element={<TaskDetailsModal />} />
        </Route>
        <Route
          path='project/:projectId/task/:taskId'
          element={<TaskDetailsModal />}
        />
        <Route
          path='goals/:goalId/tasks/:taskId'
          element={<TaskDetailsModal />}
        />
      </Route>
      <Route path='project/:projectId/:sprintId' element={<Project />} />
      <Route path='topic/:id' element={<Topic />} />
      <Route path='course/:id/edit' element={<EditCourse />} />
    </Route>

    <Route path='*' element={<p>This page does not exist.</p>} />
  </Routes>
);

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
