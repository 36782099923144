export const dasherize = (str: string) =>
  str
    .toLowerCase()
    .replace(/[^a-zA-Z\d]+/g, '-')
    .replace(/\s+/g, '-');

export const sanitizeRichText = (text: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  const body = doc.body;

  // Remove empty paragraphs
  const paragraphs = body.querySelectorAll('p');
  paragraphs.forEach((p) => {
    if (!p.textContent?.trim()) {
      p.remove();
    }
  });

  // Remove extra white spaces within text nodes
  const textNodes = body.querySelectorAll('*');
  textNodes.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      node.textContent = node.textContent?.replace(/\s+/g, ' ') || ' ';
    }
  });

  // Clean up extra line breaks
  const lineBreaks = body.querySelectorAll('br');
  lineBreaks.forEach((br) => {
    const nextSibling = br.nextSibling;
    const parentNode = br.parentNode;
    if (
      !nextSibling ||
      (nextSibling.nodeType === Node.TEXT_NODE &&
        !nextSibling.textContent?.trim()) ||
      (parentNode && parentNode.lastChild === br)
    ) {
      br.remove();
    }
  });

  // Remove <div> tags
  const divTags = body.querySelectorAll('div');
  divTags.forEach((div) => {
    // Move the children of <div> before the <div> itself
    while (div.firstChild) {
      div.parentNode?.insertBefore(div.firstChild, div);
    }
    // Remove the empty <div> after moving its children
    div.remove();
  });

  return body.innerHTML.trim();
};

export const createInitials = (name: string) => {
  const nameParts = name.split(' ');
  return nameParts.map((part) => part[0].toUpperCase()).join('');
};
